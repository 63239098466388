"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/data/logger.ts
var logger_exports = {};
__export(logger_exports, {
  filter: () => filter,
  merge: () => merge,
  pipe: () => pipe,
  toConsoleLogger: () => toConsoleLogger
});
module.exports = __toCommonJS(logger_exports);
var pipe = (fn) => (logger) => {
  return (x) => logger(fn(x));
};
var merge = (loggers) => {
  return (x) => loggers.forEach((fn) => fn(x));
};
var filter = (pred, logger) => {
  return (x) => pred(x) ? logger(x) : null;
};
var toConsoleLogger = () => merge([
  filter(
    (x) => x.level === "log",
    (x) => console.log(x)
  ),
  filter(
    (x) => x.level === "info",
    (x) => console.info(x)
  ),
  filter(
    (x) => x.level === "warning",
    (x) => console.warn(x)
  ),
  filter(
    (x) => x.level === "error",
    (x) => console.error(x)
  )
]);
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  filter,
  merge,
  pipe,
  toConsoleLogger
});
