import * as core from '@mt-webpages/core'
import * as eventEmitter from 'src/data/eventEmitter'
import * as mtWebpagesWebEvent from 'src/data/mtWebpagesWebEvent'
import * as mtWebpagesWebSessionService from 'src/data/mtWebpagesWebSessionService'
import * as hubspotEvent from 'src/data/hubspotEvent'

export type HubspotEventHandlerContext = {
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  window: Window & globalThis.Window
  mtWebpagesWebSession: mtWebpagesWebSessionService.MtWebpagesWebSessionService
  mtWebpagesWebEventEmitter: eventEmitter.EventEmitter<mtWebpagesWebEvent.MtWebpagesWebEvent>
}

export const handleEvent = (event: hubspotEvent.HubspotEvent) => (context: HubspotEventHandlerContext) => {
  context.logger({
    message: 'Handling hubspot event',
    level: 'info'
  })
  if (event.eventName === 'onFormSubmit') {
    context.mtWebpagesWebSession.setHubspotContact({
      properties: (event.data || [])
        .filter(field => core.data.hubspotContact.HubspotContactPropertyValue.guard(field.value))
        .reduce((acc, entry) => ({ ...acc, [entry.name]: { value: entry.value } }), {})
    })
  } else if (event.eventName === 'onFormSubmitted') {
    context.mtWebpagesWebEventEmitter.emit(
      mtWebpagesWebEvent.hubspotFormSubmitSuccess({
        formId: event.id
      })
    )
  }
}
