import * as runtypes from 'runtypes'
import * as core from '@mt-webpages/core'
import * as locale from './locale'
import * as hubspotFormSubmitFieldErrorTranslation from './hubspotFormSubmitFieldErrorTranslation'
import * as hubspotFormElement from 'src/data/hubspotFormElement'

export const HubspotSubmitFormScriptConfig = runtypes.Record({
  locale: runtypes.Optional(locale.Locale),
  defaultObjectTypeId: runtypes.Optional(core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId),
  formId: runtypes.String,
  formElement: hubspotFormElement.HubspotFormElement,
  formSuccessElementQuery: runtypes.Optional(runtypes.String),
  formFailureElementQuery: runtypes.Optional(runtypes.String),
  fieldErrorTranslation: runtypes.Optional(
    hubspotFormSubmitFieldErrorTranslation.HubspotFormSubmitFieldErrorTranslation
  ),
  formFieldErrorDatasetIdentifier: runtypes.Optional(runtypes.String),
  formInvalidFieldCssClassname: runtypes.Optional(runtypes.String),
  formfieldErrorListContainerCssClassname: runtypes.Optional(runtypes.String),
  formfieldErrorListCssClassname: runtypes.Optional(runtypes.String),
  formfieldErrorListItemCssClassname: runtypes.Optional(runtypes.String),
  formSubmitLoadingClassname: runtypes.Optional(runtypes.String),
  mtWebpagesWebSession: runtypes.Optional(
    runtypes.Record({
      submitHubspotForm: runtypes.Function,
      getHubspotContact: runtypes.Function,
      setHubspotContact: runtypes.Function
    })
  ),
  onFormEvent: runtypes.Optional(runtypes.Function)
})

export type HubspotSubmitFormScriptConfig = runtypes.Static<typeof HubspotSubmitFormScriptConfig>
