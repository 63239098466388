import * as runtypes from 'runtypes'
import * as mtWebpagesHubspotFormApiSubmitError from './mtWebpagesHubspotFormApiSubmitError'
import * as mtWebpagesHubspotFormApiSubmitErrorDetail from './mtWebpagesHubspotFormApiSubmitErrorDetail'
import * as hubspotFormSubmitFormValidationErrorDetail from './hubspotFormSubmitFormValidationErrorDetail'

export const HubspotSubmitFormErrorDetail = runtypes.Union(
  runtypes.Record({
    type: runtypes.Literal('ImplementationError'),
    message: runtypes.String
  }),
  hubspotFormSubmitFormValidationErrorDetail.HubspotFormSubmitFormValidationErrorDetail
)

export type HubspotSubmitFormErrorDetail = runtypes.Static<typeof HubspotSubmitFormErrorDetail>

export const fromMessage = (message: string): HubspotSubmitFormErrorDetail => {
  return { type: 'ImplementationError', message }
}

export const fromUnknownError = (unknown: unknown): HubspotSubmitFormErrorDetail => {
  if (unknown instanceof mtWebpagesHubspotFormApiSubmitError.MtWebpagesHubspotFormApiSubmitError) {
    return mtWebpagesHubspotFormApiSubmitErrorDetail.MtWebpagesHubspotFormApiSubmitErrorDetail.match(
      detail => fromMessage(detail.message),
      detail => fromMessage(detail.message),
      detail => detail,
      () => fromMessage('Unhandled error')
    )(unknown.detail)
  } else if (unknown instanceof Error) {
    return fromMessage(unknown.message)
  } else {
    return fromMessage('Unhandled error')
  }
}
