export type MtGtmEvent = HubspotFormSubmissionEvent

export type HubspotFormSubmissionEvent = {
  event: 'hs.formSubmission'
  formId: string
}

export const hubspotFormSubmissionEvent = (
  data: Omit<HubspotFormSubmissionEvent, 'event'>
): HubspotFormSubmissionEvent => {
  return {
    ...data,
    event: 'hs.formSubmission'
  }
}
