import * as core from '@mt-webpages/core'
import * as mtGtmEvent from 'src/data/mtGtmEvent'
import * as mtGtmService from 'src/data/mtGtmService'

export type MtGtmContext = {
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  window: Window & globalThis.Window
}

export const toMtGtmService = (context: MtGtmContext): mtGtmService.MtGtmService => {
  return {
    sendEvent: event => sendEvent(event)(context)
  }
}

export const sendEvent = (event: mtGtmEvent.MtGtmEvent) => (context: MtGtmContext) => {
  context.logger({
    message: 'sending event',
    level: 'info'
  })
  if (context.window.dataLayer) {
    context.window.dataLayer.push(event)
    context.logger({
      message: 'sent event',
      level: 'info'
    })
  } else {
    context.logger({
      message: 'dataLayer is not initialized',
      level: 'warning'
    })
  }
}
