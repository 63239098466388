import * as core from '@mt-webpages/core'
import * as runtypes from 'runtypes'
import * as hubspotFormFieldMetadata from './hubspotFormFieldMetadata'

export const HubspotFormFieldElement = runtypes.Union(
  runtypes.InstanceOf(HTMLInputElement).And(
    runtypes.Record({
      type: runtypes.Literal('checkbox'),
      name: runtypes.String.withConstraint(x => x.length > 0),
      value: runtypes.String,
      checked: runtypes.Boolean,
      disabled: runtypes.Optional(runtypes.Boolean),
      required: runtypes.Optional(runtypes.Boolean)
    })
  ),
  runtypes.InstanceOf(HTMLElement).And(
    runtypes.Record({
      name: runtypes.String.withConstraint(x => x.length > 0),
      value: runtypes.String,
      disabled: runtypes.Optional(runtypes.Boolean),
      required: runtypes.Optional(runtypes.Boolean)
    })
  )
)

export type HubspotFormFieldElement = runtypes.Static<typeof HubspotFormFieldElement>

export const fromElement = (element: Element): HubspotFormFieldElement | null => {
  return core.lib.runtypes.result.toNullable(HubspotFormFieldElement.validate(element))
}

export const fromEvent = (event: Event): HubspotFormFieldElement | null => {
  return core.lib.runtypes.result.toNullable(HubspotFormFieldElement.validate(event.target))
}

export const fromForm = (form: HTMLFormElement): HubspotFormFieldElement[] => {
  return core.lib.array.filterMap(fromElement, Array.from(form.elements))
}

export const fromFormByFieldName = (form: HTMLFormElement, name: string): HubspotFormFieldElement[] => {
  return core.lib.array.filterMap(fromElement, Array.from(form.querySelectorAll(`[name="${name}"]`)))
}

export const disableValidation = (field: HubspotFormFieldElement) => {
  field.removeAttribute('required')
}

export const enableValidation =
  (metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata) => (field: HubspotFormFieldElement) => {
    if (metadata.required) {
      field.setAttribute('required', '')
    }
  }

export const toHubspotFormFieldMetadata = HubspotFormFieldElement.match(
  checkbox =>
    hubspotFormFieldMetadata.HubspotFormFieldMetadata.check({
      type: checkbox.type,
      name: checkbox.name,
      value: checkbox.dataset.value || 'true', // hubspot typically expects true
      checked: checkbox.checked,
      disabled: checkbox.disabled,
      required: checkbox.required,
      objectTypeId: checkbox.dataset.objectTypeId,
      triggerFieldName: checkbox.dataset.triggerFieldName,
      triggerFieldValue: checkbox.dataset.triggerFieldValue
    }),
  input =>
    hubspotFormFieldMetadata.HubspotFormFieldMetadata.check({
      type: 'input',
      name: input.name,
      value: input.value,
      disabled: input.disabled,
      required: input.required,
      objectTypeId: input.dataset.objectTypeId,
      triggerFieldName: input.dataset.triggerFieldName,
      triggerFieldValue: input.dataset.triggerFieldValue
    })
)
