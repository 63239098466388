import * as core from '@mt-webpages/core'

export type MtWebpagesApiClientService = {
  postHubspotForm: (input: PostHubspotFormInput) => Promise<void>
  getSessionHubspotContact: (input: GetSessionHubspotContactInput) => Promise<GetSessionHubspotContactOutput>
  setSessionHubspotContact: (input: SetSessionHubspotContactInput) => Promise<void>
}

export type PostHubspotFormInput = core.data.hubspotFormSubmitInput.HubspotFormSubmitInput

export type GetSessionHubspotContactInput = core.data.getSessionHubspotContactInput.GetSessionHubspotContactInput

export type GetSessionHubspotContactOutput = core.data.getSessionHubspotContactOutput.GetSessionHubspotContactOutput

export const GetSessionHubspotContactOutput = core.data.getSessionHubspotContactOutput.GetSessionHubspotContactOutput

export type SetSessionHubspotContactInput = core.data.setSessionHubspotContactInput.SetSessionHubspotContactInput
