import * as core from '@mt-webpages/core'
import * as runtypes from 'runtypes'
import { SentrySdkScopeConfig } from 'src/data/sentrySdkScopeConfig'
import { SentrySdkConfig, SentrySdkConfigSerialized } from 'src/data/sentrySdkConfig'

export const sentryAllowUrls = [/\.*mt-webpages-web\.*/]

export const MtWebpagesConfig = runtypes.Record({
  mtWebpagesApiBaseUrl: runtypes.String,
  sentryDsn: runtypes.String,
  sentryEnvironment: runtypes.String,
  sentryRelease: runtypes.String,
  sentryTracesSampleRate: runtypes.Optional(runtypes.Number),
  sentryTags: runtypes.Optional(runtypes.String),
  sentryMinimumLogLevel: core.data.logLevel.LogLevel,
  enableConsoleLogging: runtypes.Boolean
})

export type MtWebpagesConfig = runtypes.Static<typeof MtWebpagesConfig>

export const fromEnv = (): MtWebpagesConfig => {
  return MtWebpagesConfig.check({
    mtWebpagesApiBaseUrl: process.env.MT_WEBPAGES_FORM_API_BASE_URL,
    sentryDsn: process.env.SENTRY_DSN,
    sentryEnvironment: process.env.SENTRY_ENVIRONMENT,
    sentryRelease: process.env.SENTRY_RELEASE,
    sentryTracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE
      ? parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE)
      : undefined,
    sentryTags: process.env.SENTRY_TAGS,
    sentryMinimumLogLevel: process.env.SENTRY_MINIMUM_LOG_LEVEL || 'warning',
    enableConsoleLogging: process.env.ENABLE_CONSOLE_LOGGING === 'true'
  })
}

export const toSentrySdkConfig = (config: MtWebpagesConfig): runtypes.Result<SentrySdkConfig> => {
  const baseSentrySdkConfig = SentrySdkConfigSerialized.validate({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    release: config.sentryRelease,
    tracesSampleRate: config.sentryTracesSampleRate,
    minimumLogLevel: config.sentryMinimumLogLevel
  })

  return baseSentrySdkConfig.success
    ? {
        ...baseSentrySdkConfig,
        value: {
          ...baseSentrySdkConfig.value,
          allowUrls: sentryAllowUrls
        }
      }
    : baseSentrySdkConfig
}

export const toSentrySdkScopeConfig = (config: MtWebpagesConfig): runtypes.Result<SentrySdkScopeConfig> => {
  return SentrySdkScopeConfig.validate({
    tags: (() => {
      try {
        return config.sentryTags ? JSON.parse(config.sentryTags) : undefined
      } catch (_) {
        // Hacky but forces the safeParse to fail on malformed json string
        return Symbol('')
      }
    })()
  })
}

export const toLogger = (
  config: MtWebpagesConfig
): core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>> => {
  return core.data.logger.pipe(core.data.logEntry.addContext({ config }))(
    core.data.logger.filter((x): x is typeof x => config.enableConsoleLogging, core.data.logger.toConsoleLogger())
  )
}
