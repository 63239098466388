import * as eventEmitter from 'src/data/eventEmitter'
import * as subscription from 'src/data/subscription'
import * as hubspotEvent from 'src/data/hubspotEvent'
import * as mtWebpagesWebBootstrapService from 'src/data/mtWebpagesWebBootstrapService'

export type MtWebpagesWebBootstrapContext = {
  window: globalThis.Window
  hubspotEventEmitter: eventEmitter.EventEmitter<hubspotEvent.HubspotEvent>
}

export const toMtWebpagesWebBootstrapService = (
  context: MtWebpagesWebBootstrapContext
): mtWebpagesWebBootstrapService.MtWebpagesWebBootstrapService => ({
  setup: () => setup(context)
})

export const setup = (context: MtWebpagesWebBootstrapContext): subscription.Subscription => {
  const listener = (event: MessageEvent) => {
    if (hubspotEvent.HubspotDomEvent.guard(event)) {
      context.hubspotEventEmitter.emit(event.data)
    }
  }
  context.window.addEventListener('message', listener)
  return () => context.window.removeEventListener('message', listener)
}
