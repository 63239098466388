import * as core from '@mt-webpages/core'
import * as rxjs from 'rxjs'
import * as runtypes from 'runtypes'
import * as sentry from 'src/lib/sentry'
import * as subscription from './data/subscription'
import * as eventEmitter from './data/eventEmitter'
import * as hubspotSubmitFormScriptConfig from './data/hubspotSubmitFormScriptConfig'
import * as hubspotSubmitFormsScriptConfig from './data/hubspotSubmitFormsScriptConfig'
import * as setupScriptConfig from './data/setupScriptConfig'
import * as mtWebpagesWebConfig from './data/mtWebpagesWebConfig'
import * as mtWebpagesWebContext from './data/mtWebpagesWebContext'
import * as mtWebpagesWeb from './data/mtWebpagesWeb'
import * as hubspotEvent from './data/hubspotEvent'
import * as hubspotFormEvent from './data/hubspotFormEvent'
import * as mtWebpagesWebEvent from './data/mtWebpagesWebEvent'
import * as hubspotEventHandler from './lib/hubspotEventHandler'
import * as hubspotFormEventHandler from './lib/hubspotFormEventHandler'
import * as mtGtm from './lib/mtGtm'
import * as httpClient from './lib/httpClient'
import * as sessionCookieStore from './lib/sessionCookieStore'
import * as mtWebpagesApiClient from './lib/mtWebpagesApiClient'
import * as mtWebpagesWebEventHandler from './lib/mtWebpagesWebEventHandler'
import * as mtWebpagesWebSession from './lib/mtWebpagesWebSession'
import * as mtWebpagesWebBootstrap from './lib/mtWebpagesWebBootstrap'
import * as scripts from './scripts'

export const main = () => {
  const config = mtWebpagesWebConfig.fromEnv()
  let logger = mtWebpagesWebConfig.toLogger(config)
  const sentrySdkConfig = mtWebpagesWebConfig.toSentrySdkConfig(config)
  const sentrySdkScopeConfig = mtWebpagesWebConfig.toSentrySdkScopeConfig(config)
  if (sentrySdkConfig.success) {
    logger = core.data.logger.merge([logger, sentry.createLogger(sentrySdkConfig.value)])
    sentry.configureSdk(sentrySdkConfig.value)
    if (sentrySdkScopeConfig.success) {
      sentry.configureScope(sentrySdkScopeConfig.value)
    } else {
      logger({
        message: new runtypes.ValidationError(sentrySdkScopeConfig),
        level: 'warning'
      })
    }
  } else {
    logger({
      message: `Unable to configure Sentry SDK`,
      level: 'warning'
    })
  }
  const hubspotEventSubject = new rxjs.Subject<hubspotEvent.HubspotEvent>()
  const husbpotFormEventSubject = new rxjs.Subject<hubspotFormEvent.HubspotFormEvent>()
  const mtWebpagesWebEventSubject = new rxjs.Subject<mtWebpagesWebEvent.MtWebpagesWebEvent>()
  const httpClientService = httpClient.toHttpClientService({
    client: fetch,
    logger: core.data.logger.pipe(
      core.data.logEntry.addContext({
        service: 'httpClient'
      })
    )(logger)
  })
  const sessionStoreService = sessionCookieStore.toSessionStoreService({
    window: globalThis.window
  })
  const mtWebpagesApiClientService = mtWebpagesApiClient.toMtWebpagesApiClientService({
    client: httpClientService,
    baseUrl: config.mtWebpagesApiBaseUrl,
    logger: core.data.logger.pipe(
      core.data.logEntry.addContext({
        service: 'mtWebpagesApiClient'
      })
    )(logger)
  })
  const mtGtmService = mtGtm.toMtGtmService({
    window: globalThis.window,
    logger: core.data.logger.pipe(
      core.data.logEntry.addContext({
        service: 'mtGtm'
      })
    )(logger)
  })
  const mtWebpagesWebBootstrapService = mtWebpagesWebBootstrap.toMtWebpagesWebBootstrapService({
    window: globalThis.window,
    hubspotEventEmitter: eventEmitter.fromSubject(hubspotEventSubject)
  })
  const mtWebpagesWebSessionService = mtWebpagesWebSession.toMtWebpagesWebSessionService({
    window: globalThis.window,
    sessionStore: sessionStoreService,
    mtWebpagesApiClient: mtWebpagesApiClientService,
    hubspotUtkSessionKey: mtWebpagesWebContext.defaultContext.hubspotUtkCookieName,
    logger: core.data.logger.pipe(
      core.data.logEntry.addContext({
        service: 'mtWebpagesWebSession'
      })
    )(logger)
  })
  hubspotEventSubject.subscribe(event =>
    hubspotEventHandler.handleEvent(event)({
      mtWebpagesWebEventEmitter: eventEmitter.fromSubject(mtWebpagesWebEventSubject),
      mtWebpagesWebSession: mtWebpagesWebSessionService,
      window: globalThis.window,
      logger: core.data.logger.pipe(
        core.data.logEntry.addContext({
          service: 'hubspotEventHandler'
        })
      )(logger)
    })
  )
  husbpotFormEventSubject.subscribe(event =>
    hubspotFormEventHandler.handleEvent(event)({
      mtWebpagesWebEventEmitter: eventEmitter.fromSubject(mtWebpagesWebEventSubject),
      window: globalThis.window,
      logger: core.data.logger.pipe(
        core.data.logEntry.addContext({
          service: 'hubspotEventHandler'
        })
      )(logger)
    })
  )
  mtWebpagesWebEventSubject.subscribe(event =>
    mtWebpagesWebEventHandler.handleEvent(event)({
      window: globalThis.window,
      mtGtm: mtGtmService,
      logger: core.data.logger.pipe(
        core.data.logEntry.addContext({
          service: 'mtWebpagesWebEventHandlerService'
        })
      )(logger)
    })
  )
  const context: mtWebpagesWebContext.MtWebpagesWebContext = {
    ...mtWebpagesWebContext.defaultContext,
    logger,
    httpClient: httpClientService,
    mtGtm: mtGtmService,
    mtWebpagesApiClient: mtWebpagesApiClientService,
    mtWebpagesWebBootstrap: mtWebpagesWebBootstrapService,
    mtWebpagesWebSession: mtWebpagesWebSessionService,
    hubspotFormConfig: {
      ...mtWebpagesWebContext.defaultContext.hubspotFormConfig
    }
  }
  const MtWebpagesWeb: mtWebpagesWeb.MtWebpagesWeb = {
    scripts: {
      setup: subscription.onWindowLoad<setupScriptConfig.SetupScriptOptions>(options =>
        scripts.setup.main(options)(context)
      )(context.window),
      hubspotSubmitForm: {
        from: subscription.onWindowLoad<hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig>(options =>
          scripts.hubspotSubmitForm.from(options)(context)
        )(context.window)
      },
      hubspotSubmitForms: {
        from: subscription.onWindowLoad<hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfig>(options =>
          scripts.hubspotSubmitForms.from(options)(context)
        )(context.window)
      }
    }
  }

  Object.assign(globalThis, { MtWebpagesWeb })
  mtWebpagesWebEventSubject.next(mtWebpagesWebEvent.load)
}

main()
