"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// ../../node_modules/runtypes/lib/reflect.js
var require_reflect = __commonJS({
  "../../node_modules/runtypes/lib/reflect.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
  }
});

// ../../node_modules/runtypes/lib/result.js
var require_result = __commonJS({
  "../../node_modules/runtypes/lib/result.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Failcode = void 0;
    exports.Failcode = {
      /** The type of the received primitive value is incompatible with expected one. */
      TYPE_INCORRECT: "TYPE_INCORRECT",
      /** The received primitive value is incorrect. */
      VALUE_INCORRECT: "VALUE_INCORRECT",
      /** The key of the property is incorrect. */
      KEY_INCORRECT: "KEY_INCORRECT",
      /** One or more elements or properties of the received object are incorrect. */
      CONTENT_INCORRECT: "CONTENT_INCORRECT",
      /** One or more arguments passed to the function is incorrect. */
      ARGUMENT_INCORRECT: "ARGUMENT_INCORRECT",
      /** The value returned by the function is incorrect. */
      RETURN_INCORRECT: "RETURN_INCORRECT",
      /** The received value does not fulfill the constraint. */
      CONSTRAINT_FAILED: "CONSTRAINT_FAILED",
      /** The property must be present but missing. */
      PROPERTY_MISSING: "PROPERTY_MISSING",
      /** The property must not be present but present. */
      PROPERTY_PRESENT: "PROPERTY_PRESENT",
      /** The value must not be present but present. */
      NOTHING_EXPECTED: "NOTHING_EXPECTED"
    };
  }
});

// ../../node_modules/runtypes/lib/errors.js
var require_errors = __commonJS({
  "../../node_modules/runtypes/lib/errors.js"(exports) {
    "use strict";
    var __extends = exports && exports.__extends || function() {
      var extendStatics = function(d, b) {
        extendStatics = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(d2, b2) {
          d2.__proto__ = b2;
        } || function(d2, b2) {
          for (var p in b2)
            if (Object.prototype.hasOwnProperty.call(b2, p))
              d2[p] = b2[p];
        };
        return extendStatics(d, b);
      };
      return function(d, b) {
        if (typeof b !== "function" && b !== null)
          throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() {
          this.constructor = d;
        }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
      };
    }();
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.ValidationError = void 0;
    var ValidationError = (
      /** @class */
      function(_super) {
        __extends(ValidationError2, _super);
        function ValidationError2(failure) {
          var _this = _super.call(this, failure.message) || this;
          _this.name = "ValidationError";
          _this.code = failure.code;
          if (failure.details !== void 0)
            _this.details = failure.details;
          Object.setPrototypeOf(_this, ValidationError2.prototype);
          return _this;
        }
        return ValidationError2;
      }(Error)
    );
    exports.ValidationError = ValidationError;
  }
});

// ../../node_modules/runtypes/lib/show.js
var require_show = __commonJS({
  "../../node_modules/runtypes/lib/show.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    var showStringified = function(circular) {
      return function(refl) {
        switch (refl.tag) {
          case "literal":
            return '"'.concat(String(refl.value), '"');
          case "string":
            return "string";
          case "brand":
            return refl.brand;
          case "constraint":
            return refl.name || showStringified(circular)(refl.underlying);
          case "union":
            return refl.alternatives.map(showStringified(circular)).join(" | ");
          case "intersect":
            return refl.intersectees.map(showStringified(circular)).join(" & ");
          default:
            break;
        }
        return "`${".concat(show(false, circular)(refl), "}`");
      };
    };
    var showEmbedded = function(circular) {
      return function(refl) {
        switch (refl.tag) {
          case "literal":
            return String(refl.value);
          case "brand":
            return "${".concat(refl.brand, "}");
          case "constraint":
            return refl.name ? "${".concat(refl.name, "}") : showEmbedded(circular)(refl.underlying);
          case "union":
            if (refl.alternatives.length === 1) {
              var inner = refl.alternatives[0];
              return showEmbedded(circular)(inner.reflect);
            }
            break;
          case "intersect":
            if (refl.intersectees.length === 1) {
              var inner = refl.intersectees[0];
              return showEmbedded(circular)(inner.reflect);
            }
            break;
          default:
            break;
        }
        return "${".concat(show(false, circular)(refl), "}");
      };
    };
    var show = function(needsParens, circular) {
      return function(refl) {
        var parenthesize = function(s) {
          return needsParens ? "(".concat(s, ")") : s;
        };
        if (circular.has(refl))
          return parenthesize("CIRCULAR ".concat(refl.tag));
        else
          circular.add(refl);
        try {
          switch (refl.tag) {
            case "unknown":
            case "never":
            case "void":
            case "boolean":
            case "number":
            case "bigint":
            case "string":
            case "symbol":
            case "function":
              return refl.tag;
            case "literal": {
              var value = refl.value;
              return typeof value === "string" ? '"'.concat(value, '"') : String(value);
            }
            case "template": {
              if (refl.strings.length === 0)
                return '""';
              else if (refl.strings.length === 1)
                return '"'.concat(refl.strings[0], '"');
              else if (refl.strings.length === 2) {
                if (refl.strings.every(function(string) {
                  return string === "";
                })) {
                  var runtype = refl.runtypes[0];
                  return showStringified(circular)(runtype.reflect);
                }
              }
              var backtick_1 = false;
              var inner = refl.strings.reduce(function(inner2, string, i) {
                var prefix = inner2 + string;
                var runtype2 = refl.runtypes[i];
                if (runtype2) {
                  var suffix = showEmbedded(circular)(runtype2.reflect);
                  if (!backtick_1 && suffix.startsWith("$"))
                    backtick_1 = true;
                  return prefix + suffix;
                } else
                  return prefix;
              }, "");
              return backtick_1 ? "`".concat(inner, "`") : '"'.concat(inner, '"');
            }
            case "array":
              return "".concat(readonlyTag(refl)).concat(show(true, circular)(refl.element), "[]");
            case "dictionary":
              return "{ [_: ".concat(refl.key, "]: ").concat(show(false, circular)(refl.value), " }");
            case "record": {
              var keys = Object.keys(refl.fields);
              return keys.length ? "{ ".concat(keys.map(function(k) {
                return "".concat(readonlyTag(refl)).concat(k).concat(partialTag(refl, k), ": ").concat(refl.fields[k].tag === "optional" ? show(false, circular)(refl.fields[k].underlying) : show(false, circular)(refl.fields[k]), ";");
              }).join(" "), " }") : "{}";
            }
            case "tuple":
              return "[".concat(refl.components.map(show(false, circular)).join(", "), "]");
            case "union":
              return parenthesize("".concat(refl.alternatives.map(show(true, circular)).join(" | ")));
            case "intersect":
              return parenthesize("".concat(refl.intersectees.map(show(true, circular)).join(" & ")));
            case "optional":
              return show(needsParens, circular)(refl.underlying) + " | undefined";
            case "constraint":
              return refl.name || show(needsParens, circular)(refl.underlying);
            case "instanceof":
              return refl.ctor.name;
            case "brand":
              return show(needsParens, circular)(refl.entity);
          }
        } finally {
          circular.delete(refl);
        }
        throw Error("impossible");
      };
    };
    exports.default = show(false, /* @__PURE__ */ new Set());
    function partialTag(_a, key) {
      var isPartial = _a.isPartial, fields = _a.fields;
      return isPartial || key !== void 0 && fields[key].tag === "optional" ? "?" : "";
    }
    function readonlyTag(_a) {
      var isReadonly = _a.isReadonly;
      return isReadonly ? "readonly " : "";
    }
  }
});

// ../../node_modules/runtypes/lib/util.js
var require_util = __commonJS({
  "../../node_modules/runtypes/lib/util.js"(exports) {
    "use strict";
    var __assign = exports && exports.__assign || function() {
      __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i];
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p))
              t[p] = s[p];
        }
        return t;
      };
      return __assign.apply(this, arguments);
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.FAILURE = exports.SUCCESS = exports.enumerableKeysOf = exports.typeOf = exports.hasKey = void 0;
    var result_1 = require_result();
    var show_1 = require_show();
    function hasKey(key, object) {
      return typeof object === "object" && object !== null && key in object;
    }
    exports.hasKey = hasKey;
    var typeOf = function(value) {
      var _a, _b, _c;
      return typeof value === "object" ? value === null ? "null" : Array.isArray(value) ? "array" : ((_a = value.constructor) === null || _a === void 0 ? void 0 : _a.name) === "Object" ? "object" : (_c = (_b = value.constructor) === null || _b === void 0 ? void 0 : _b.name) !== null && _c !== void 0 ? _c : typeof value : typeof value;
    };
    exports.typeOf = typeOf;
    var enumerableKeysOf = function(object) {
      return typeof object === "object" && object !== null ? (
        // Objects with a null prototype may not have `propertyIsEnumerable`
        Reflect.ownKeys(object).filter(function(key) {
          var _a, _b;
          return (_b = (_a = object.propertyIsEnumerable) === null || _a === void 0 ? void 0 : _a.call(object, key)) !== null && _b !== void 0 ? _b : true;
        })
      ) : [];
    };
    exports.enumerableKeysOf = enumerableKeysOf;
    function SUCCESS(value) {
      return { success: true, value };
    }
    exports.SUCCESS = SUCCESS;
    exports.FAILURE = Object.assign(function(code, message, details) {
      return __assign({ success: false, code, message }, details ? { details } : {});
    }, {
      TYPE_INCORRECT: function(self, value) {
        var message = "Expected ".concat(self.tag === "template" ? "string ".concat((0, show_1.default)(self)) : (0, show_1.default)(self), ", but was ").concat((0, exports.typeOf)(value));
        return (0, exports.FAILURE)(result_1.Failcode.TYPE_INCORRECT, message);
      },
      VALUE_INCORRECT: function(name, expected, received) {
        return (0, exports.FAILURE)(result_1.Failcode.VALUE_INCORRECT, "Expected ".concat(name, " ").concat(String(expected), ", but was ").concat(String(received)));
      },
      KEY_INCORRECT: function(self, expected, value) {
        return (0, exports.FAILURE)(result_1.Failcode.KEY_INCORRECT, "Expected ".concat((0, show_1.default)(self), " key to be ").concat((0, show_1.default)(expected), ", but was ").concat((0, exports.typeOf)(value)));
      },
      CONTENT_INCORRECT: function(self, details) {
        var formattedDetails = JSON.stringify(details, null, 2).replace(/^ *null,\n/gm, "");
        var message = "Validation failed:\n".concat(formattedDetails, ".\nObject should match ").concat((0, show_1.default)(self));
        return (0, exports.FAILURE)(result_1.Failcode.CONTENT_INCORRECT, message, details);
      },
      ARGUMENT_INCORRECT: function(message) {
        return (0, exports.FAILURE)(result_1.Failcode.ARGUMENT_INCORRECT, message);
      },
      RETURN_INCORRECT: function(message) {
        return (0, exports.FAILURE)(result_1.Failcode.RETURN_INCORRECT, message);
      },
      CONSTRAINT_FAILED: function(self, message) {
        var info = message ? ": ".concat(message) : "";
        return (0, exports.FAILURE)(result_1.Failcode.CONSTRAINT_FAILED, "Failed constraint check for ".concat((0, show_1.default)(self)).concat(info));
      },
      PROPERTY_MISSING: function(self) {
        var message = "Expected ".concat((0, show_1.default)(self), ", but was missing");
        return (0, exports.FAILURE)(result_1.Failcode.PROPERTY_MISSING, message);
      },
      PROPERTY_PRESENT: function(value) {
        var message = "Expected nothing, but was ".concat((0, exports.typeOf)(value));
        return (0, exports.FAILURE)(result_1.Failcode.PROPERTY_PRESENT, message);
      },
      NOTHING_EXPECTED: function(value) {
        var message = "Expected nothing, but was ".concat((0, exports.typeOf)(value));
        return (0, exports.FAILURE)(result_1.Failcode.NOTHING_EXPECTED, message);
      }
    });
  }
});

// ../../node_modules/runtypes/lib/contract.js
var require_contract = __commonJS({
  "../../node_modules/runtypes/lib/contract.js"(exports) {
    "use strict";
    var __read = exports && exports.__read || function(o, n) {
      var m = typeof Symbol === "function" && o[Symbol.iterator];
      if (!m)
        return o;
      var i = m.call(o), r, ar = [], e;
      try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
          ar.push(r.value);
      } catch (error) {
        e = { error };
      } finally {
        try {
          if (r && !r.done && (m = i["return"]))
            m.call(i);
        } finally {
          if (e)
            throw e.error;
        }
      }
      return ar;
    };
    var __spreadArray = exports && exports.__spreadArray || function(to, from, pack) {
      if (pack || arguments.length === 2)
        for (var i = 0, l = from.length, ar; i < l; i++) {
          if (ar || !(i in from)) {
            if (!ar)
              ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
          }
        }
      return to.concat(ar || Array.prototype.slice.call(from));
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Contract = void 0;
    var errors_1 = require_errors();
    var util_1 = require_util();
    function Contract() {
      var runtypes15 = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        runtypes15[_i] = arguments[_i];
      }
      var lastIndex = runtypes15.length - 1;
      var argRuntypes = runtypes15.slice(0, lastIndex);
      var returnRuntype = runtypes15[lastIndex];
      return {
        enforce: function(f) {
          return function() {
            var args = [];
            for (var _i2 = 0; _i2 < arguments.length; _i2++) {
              args[_i2] = arguments[_i2];
            }
            if (args.length < argRuntypes.length) {
              var message = "Expected ".concat(argRuntypes.length, " arguments but only received ").concat(args.length);
              var failure = util_1.FAILURE.ARGUMENT_INCORRECT(message);
              throw new errors_1.ValidationError(failure);
            }
            for (var i = 0; i < argRuntypes.length; i++)
              argRuntypes[i].check(args[i]);
            return returnRuntype.check(f.apply(void 0, __spreadArray([], __read(args), false)));
          };
        }
      };
    }
    exports.Contract = Contract;
  }
});

// ../../node_modules/runtypes/lib/asynccontract.js
var require_asynccontract = __commonJS({
  "../../node_modules/runtypes/lib/asynccontract.js"(exports) {
    "use strict";
    var __read = exports && exports.__read || function(o, n) {
      var m = typeof Symbol === "function" && o[Symbol.iterator];
      if (!m)
        return o;
      var i = m.call(o), r, ar = [], e;
      try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
          ar.push(r.value);
      } catch (error) {
        e = { error };
      } finally {
        try {
          if (r && !r.done && (m = i["return"]))
            m.call(i);
        } finally {
          if (e)
            throw e.error;
        }
      }
      return ar;
    };
    var __spreadArray = exports && exports.__spreadArray || function(to, from, pack) {
      if (pack || arguments.length === 2)
        for (var i = 0, l = from.length, ar; i < l; i++) {
          if (ar || !(i in from)) {
            if (!ar)
              ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
          }
        }
      return to.concat(ar || Array.prototype.slice.call(from));
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.AsyncContract = void 0;
    var errors_1 = require_errors();
    var util_1 = require_util();
    function AsyncContract() {
      var runtypes15 = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        runtypes15[_i] = arguments[_i];
      }
      var lastIndex = runtypes15.length - 1;
      var argRuntypes = runtypes15.slice(0, lastIndex);
      var returnRuntype = runtypes15[lastIndex];
      return {
        enforce: function(f) {
          return function() {
            var args = [];
            for (var _i2 = 0; _i2 < arguments.length; _i2++) {
              args[_i2] = arguments[_i2];
            }
            if (args.length < argRuntypes.length) {
              var message = "Expected ".concat(argRuntypes.length, " arguments but only received ").concat(args.length);
              var failure = util_1.FAILURE.ARGUMENT_INCORRECT(message);
              throw new errors_1.ValidationError(failure);
            }
            for (var i = 0; i < argRuntypes.length; i++)
              argRuntypes[i].check(args[i]);
            var returnedPromise = f.apply(void 0, __spreadArray([], __read(args), false));
            if (!(returnedPromise instanceof Promise)) {
              var message = "Expected function to return a promise, but instead got ".concat(returnedPromise);
              var failure = util_1.FAILURE.RETURN_INCORRECT(message);
              throw new errors_1.ValidationError(failure);
            }
            return returnedPromise.then(returnRuntype.check);
          };
        }
      };
    }
    exports.AsyncContract = AsyncContract;
  }
});

// ../../node_modules/runtypes/lib/match.js
var require_match = __commonJS({
  "../../node_modules/runtypes/lib/match.js"(exports) {
    "use strict";
    var __values = exports && exports.__values || function(o) {
      var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
      if (m)
        return m.call(o);
      if (o && typeof o.length === "number")
        return {
          next: function() {
            if (o && i >= o.length)
              o = void 0;
            return { value: o && o[i++], done: !o };
          }
        };
      throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
    };
    var __read = exports && exports.__read || function(o, n) {
      var m = typeof Symbol === "function" && o[Symbol.iterator];
      if (!m)
        return o;
      var i = m.call(o), r, ar = [], e;
      try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
          ar.push(r.value);
      } catch (error) {
        e = { error };
      } finally {
        try {
          if (r && !r.done && (m = i["return"]))
            m.call(i);
        } finally {
          if (e)
            throw e.error;
        }
      }
      return ar;
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.when = exports.match = void 0;
    function match() {
      var cases = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        cases[_i] = arguments[_i];
      }
      return function(x) {
        var e_1, _a;
        try {
          for (var cases_1 = __values(cases), cases_1_1 = cases_1.next(); !cases_1_1.done; cases_1_1 = cases_1.next()) {
            var _b = __read(cases_1_1.value, 2), T = _b[0], f = _b[1];
            if (T.guard(x))
              return f(x);
          }
        } catch (e_1_1) {
          e_1 = { error: e_1_1 };
        } finally {
          try {
            if (cases_1_1 && !cases_1_1.done && (_a = cases_1.return))
              _a.call(cases_1);
          } finally {
            if (e_1)
              throw e_1.error;
          }
        }
        throw new Error("No alternatives were matched");
      };
    }
    exports.match = match;
    function when(runtype, transformer) {
      return [runtype, transformer];
    }
    exports.when = when;
  }
});

// ../../node_modules/runtypes/lib/runtype.js
var require_runtype = __commonJS({
  "../../node_modules/runtypes/lib/runtype.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.innerValidate = exports.create = exports.isRuntype = void 0;
    var index_1 = require_lib();
    var show_1 = require_show();
    var errors_1 = require_errors();
    var util_1 = require_util();
    var RuntypeSymbol = Symbol();
    var isRuntype = function(x) {
      return (0, util_1.hasKey)(RuntypeSymbol, x);
    };
    exports.isRuntype = isRuntype;
    function create(validate, A) {
      A[RuntypeSymbol] = true;
      A.check = check;
      A.assert = check;
      A._innerValidate = function(value, visited) {
        if (visited.has(value, A))
          return (0, util_1.SUCCESS)(value);
        return validate(value, visited);
      };
      A.validate = function(value) {
        return A._innerValidate(value, VisitedState());
      };
      A.guard = guard;
      A.Or = Or;
      A.And = And;
      A.optional = optional;
      A.nullable = nullable;
      A.withConstraint = withConstraint;
      A.withGuard = withGuard;
      A.withBrand = withBrand;
      A.reflect = A;
      A.toString = function() {
        return "Runtype<".concat((0, show_1.default)(A), ">");
      };
      return A;
      function check(x) {
        var result = A.validate(x);
        if (result.success)
          return result.value;
        else
          throw new errors_1.ValidationError(result);
      }
      function guard(x) {
        return A.validate(x).success;
      }
      function Or(B) {
        return (0, index_1.Union)(A, B);
      }
      function And(B) {
        return (0, index_1.Intersect)(A, B);
      }
      function optional() {
        return (0, index_1.Optional)(A);
      }
      function nullable() {
        return (0, index_1.Union)(A, index_1.Null);
      }
      function withConstraint(constraint, options) {
        return (0, index_1.Constraint)(A, constraint, options);
      }
      function withGuard(guard2, options) {
        return (0, index_1.Constraint)(A, guard2, options);
      }
      function withBrand(B) {
        return (0, index_1.Brand)(B, A);
      }
    }
    exports.create = create;
    function innerValidate(targetType, value, visited) {
      return targetType._innerValidate(value, visited);
    }
    exports.innerValidate = innerValidate;
    function VisitedState() {
      var members = /* @__PURE__ */ new WeakMap();
      var add = function(candidate, type) {
        if (candidate === null || !(typeof candidate === "object"))
          return;
        var typeSet = members.get(candidate);
        members.set(candidate, typeSet ? typeSet.set(type, true) : (/* @__PURE__ */ new WeakMap()).set(type, true));
      };
      var has = function(candidate, type) {
        var typeSet = members.get(candidate);
        var value = typeSet && typeSet.get(type) || false;
        add(candidate, type);
        return value;
      };
      return { has };
    }
  }
});

// ../../node_modules/runtypes/lib/types/unknown.js
var require_unknown = __commonJS({
  "../../node_modules/runtypes/lib/types/unknown.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Unknown = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "unknown" };
    exports.Unknown = (0, runtype_1.create)(function(value) {
      return (0, util_1.SUCCESS)(value);
    }, self);
  }
});

// ../../node_modules/runtypes/lib/types/never.js
var require_never = __commonJS({
  "../../node_modules/runtypes/lib/types/never.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Never = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "never" };
    exports.Never = (0, runtype_1.create)(util_1.FAILURE.NOTHING_EXPECTED, self);
  }
});

// ../../node_modules/runtypes/lib/types/void.js
var require_void = __commonJS({
  "../../node_modules/runtypes/lib/types/void.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Void = void 0;
    var unknown_1 = require_unknown();
    exports.Void = unknown_1.Unknown;
  }
});

// ../../node_modules/runtypes/lib/types/union.js
var require_union = __commonJS({
  "../../node_modules/runtypes/lib/types/union.js"(exports) {
    "use strict";
    var __values = exports && exports.__values || function(o) {
      var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
      if (m)
        return m.call(o);
      if (o && typeof o.length === "number")
        return {
          next: function() {
            if (o && i >= o.length)
              o = void 0;
            return { value: o && o[i++], done: !o };
          }
        };
      throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Union = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function Union7() {
      var alternatives = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        alternatives[_i] = arguments[_i];
      }
      var match = function() {
        var cases = [];
        for (var _i2 = 0; _i2 < arguments.length; _i2++) {
          cases[_i2] = arguments[_i2];
        }
        return function(x) {
          for (var i = 0; i < alternatives.length; i++) {
            if (alternatives[i].guard(x)) {
              return cases[i](x);
            }
          }
        };
      };
      var self = { tag: "union", alternatives, match };
      return (0, runtype_1.create)(function(value, visited) {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d;
        if (typeof value !== "object" || value === null) {
          try {
            for (var alternatives_1 = __values(alternatives), alternatives_1_1 = alternatives_1.next(); !alternatives_1_1.done; alternatives_1_1 = alternatives_1.next()) {
              var alternative = alternatives_1_1.value;
              if ((0, runtype_1.innerValidate)(alternative, value, visited).success)
                return (0, util_1.SUCCESS)(value);
            }
          } catch (e_1_1) {
            e_1 = { error: e_1_1 };
          } finally {
            try {
              if (alternatives_1_1 && !alternatives_1_1.done && (_a = alternatives_1.return))
                _a.call(alternatives_1);
            } finally {
              if (e_1)
                throw e_1.error;
            }
          }
          return util_1.FAILURE.TYPE_INCORRECT(self, value);
        }
        var commonLiteralFields = {};
        try {
          for (var alternatives_2 = __values(alternatives), alternatives_2_1 = alternatives_2.next(); !alternatives_2_1.done; alternatives_2_1 = alternatives_2.next()) {
            var alternative = alternatives_2_1.value;
            if (alternative.reflect.tag === "record") {
              var _loop_1 = function(fieldName2) {
                var field2 = alternative.reflect.fields[fieldName2];
                if (field2.tag === "literal") {
                  if (commonLiteralFields[fieldName2]) {
                    if (commonLiteralFields[fieldName2].every(function(value2) {
                      return value2 !== field2.value;
                    })) {
                      commonLiteralFields[fieldName2].push(field2.value);
                    }
                  } else {
                    commonLiteralFields[fieldName2] = [field2.value];
                  }
                }
              };
              for (var fieldName in alternative.reflect.fields) {
                _loop_1(fieldName);
              }
            }
          }
        } catch (e_2_1) {
          e_2 = { error: e_2_1 };
        } finally {
          try {
            if (alternatives_2_1 && !alternatives_2_1.done && (_b = alternatives_2.return))
              _b.call(alternatives_2);
          } finally {
            if (e_2)
              throw e_2.error;
          }
        }
        for (var fieldName in commonLiteralFields) {
          if (commonLiteralFields[fieldName].length === alternatives.length) {
            try {
              for (var alternatives_3 = (e_3 = void 0, __values(alternatives)), alternatives_3_1 = alternatives_3.next(); !alternatives_3_1.done; alternatives_3_1 = alternatives_3.next()) {
                var alternative = alternatives_3_1.value;
                if (alternative.reflect.tag === "record") {
                  var field = alternative.reflect.fields[fieldName];
                  if (field.tag === "literal" && (0, util_1.hasKey)(fieldName, value) && value[fieldName] === field.value) {
                    return (0, runtype_1.innerValidate)(alternative, value, visited);
                  }
                }
              }
            } catch (e_3_1) {
              e_3 = { error: e_3_1 };
            } finally {
              try {
                if (alternatives_3_1 && !alternatives_3_1.done && (_c = alternatives_3.return))
                  _c.call(alternatives_3);
              } finally {
                if (e_3)
                  throw e_3.error;
              }
            }
          }
        }
        try {
          for (var alternatives_4 = __values(alternatives), alternatives_4_1 = alternatives_4.next(); !alternatives_4_1.done; alternatives_4_1 = alternatives_4.next()) {
            var targetType = alternatives_4_1.value;
            if ((0, runtype_1.innerValidate)(targetType, value, visited).success)
              return (0, util_1.SUCCESS)(value);
          }
        } catch (e_4_1) {
          e_4 = { error: e_4_1 };
        } finally {
          try {
            if (alternatives_4_1 && !alternatives_4_1.done && (_d = alternatives_4.return))
              _d.call(alternatives_4);
          } finally {
            if (e_4)
              throw e_4.error;
          }
        }
        return util_1.FAILURE.TYPE_INCORRECT(self, value);
      }, self);
    }
    exports.Union = Union7;
  }
});

// ../../node_modules/runtypes/lib/types/literal.js
var require_literal = __commonJS({
  "../../node_modules/runtypes/lib/types/literal.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Nullish = exports.Null = exports.Undefined = exports.Literal = exports.literal = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var union_1 = require_union();
    function literal(value) {
      return Array.isArray(value) ? String(value.map(String)) : typeof value === "bigint" ? String(value) + "n" : String(value);
    }
    exports.literal = literal;
    function Literal6(valueBase) {
      var self = { tag: "literal", value: valueBase };
      return (0, runtype_1.create)(function(value) {
        return value === valueBase ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.VALUE_INCORRECT("literal", "`".concat(literal(valueBase), "`"), "`".concat(literal(value), "`"));
      }, self);
    }
    exports.Literal = Literal6;
    exports.Undefined = Literal6(void 0);
    exports.Null = Literal6(null);
    exports.Nullish = (0, union_1.Union)(exports.Null, exports.Undefined);
  }
});

// ../../node_modules/runtypes/lib/types/template.js
var require_template = __commonJS({
  "../../node_modules/runtypes/lib/types/template.js"(exports) {
    "use strict";
    var __read = exports && exports.__read || function(o, n) {
      var m = typeof Symbol === "function" && o[Symbol.iterator];
      if (!m)
        return o;
      var i = m.call(o), r, ar = [], e;
      try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
          ar.push(r.value);
      } catch (error) {
        e = { error };
      } finally {
        try {
          if (r && !r.done && (m = i["return"]))
            m.call(i);
        } finally {
          if (e)
            throw e.error;
        }
      }
      return ar;
    };
    var __spreadArray = exports && exports.__spreadArray || function(to, from, pack) {
      if (pack || arguments.length === 2)
        for (var i = 0, l = from.length, ar; i < l; i++) {
          if (ar || !(i in from)) {
            if (!ar)
              ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
          }
        }
      return to.concat(ar || Array.prototype.slice.call(from));
    };
    var __values = exports && exports.__values || function(o) {
      var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
      if (m)
        return m.call(o);
      if (o && typeof o.length === "number")
        return {
          next: function() {
            if (o && i >= o.length)
              o = void 0;
            return { value: o && o[i++], done: !o };
          }
        };
      throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Template = void 0;
    var runtype_1 = require_runtype();
    var show_1 = require_show();
    var util_1 = require_util();
    var literal_1 = require_literal();
    var escapeRegExp = function(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    };
    var parseArgs = function(args) {
      if (0 < args.length && Array.isArray(args[0])) {
        var _a = __read(args), strings = _a[0], runtypes15 = _a.slice(1);
        return [Array.from(strings), runtypes15];
      } else {
        var convenient = args;
        var strings = convenient.reduce(function(strings2, arg) {
          if (!(0, runtype_1.isRuntype)(arg))
            strings2.push(strings2.pop() + String(arg));
          else
            strings2.push("");
          return strings2;
        }, [""]);
        var runtypes15 = convenient.filter(runtype_1.isRuntype);
        return [strings, runtypes15];
      }
    };
    var flattenInnerRuntypes = function(strings, runtypes15) {
      for (var i = 0; i < runtypes15.length; ) {
        switch (runtypes15[i].reflect.tag) {
          case "literal": {
            var literal_2 = runtypes15[i];
            runtypes15.splice(i, 1);
            var string = String(literal_2.value);
            strings.splice(i, 2, strings[i] + string + strings[i + 1]);
            break;
          }
          case "template": {
            var template = runtypes15[i];
            runtypes15.splice.apply(runtypes15, __spreadArray([i, 1], __read(template.runtypes), false));
            var innerStrings = template.strings;
            if (innerStrings.length === 1) {
              strings.splice(i, 2, strings[i] + innerStrings[0] + strings[i + 1]);
            } else {
              var first = innerStrings[0];
              var rest = innerStrings.slice(1, -1);
              var last = innerStrings[innerStrings.length - 1];
              strings.splice.apply(strings, __spreadArray(__spreadArray([i, 2, strings[i] + first], __read(rest), false), [last + strings[i + 1]], false));
            }
            break;
          }
          case "union": {
            var union = runtypes15[i];
            if (union.alternatives.length === 1) {
              try {
                var literal_3 = getInnerLiteral(union);
                runtypes15.splice(i, 1);
                var string = String(literal_3.value);
                strings.splice(i, 2, strings[i] + string + strings[i + 1]);
                break;
              } catch (_) {
                i++;
                break;
              }
            } else {
              i++;
              break;
            }
          }
          case "intersect": {
            var intersect = runtypes15[i];
            if (intersect.intersectees.length === 1) {
              try {
                var literal_4 = getInnerLiteral(intersect);
                runtypes15.splice(i, 1);
                var string = String(literal_4.value);
                strings.splice(i, 2, strings[i] + string + strings[i + 1]);
                break;
              } catch (_) {
                i++;
                break;
              }
            } else {
              i++;
              break;
            }
          }
          default:
            i++;
            break;
        }
      }
    };
    var normalizeArgs = function(args) {
      var _a = __read(parseArgs(args), 2), strings = _a[0], runtypes15 = _a[1];
      flattenInnerRuntypes(strings, runtypes15);
      return [strings, runtypes15];
    };
    var getInnerLiteral = function(runtype) {
      switch (runtype.reflect.tag) {
        case "literal":
          return runtype;
        case "brand":
          return getInnerLiteral(runtype.reflect.entity);
        case "union":
          if (runtype.reflect.alternatives.length === 1)
            return getInnerLiteral(runtype.reflect.alternatives[0]);
          break;
        case "intersect":
          if (runtype.reflect.intersectees.length === 1)
            return getInnerLiteral(runtype.reflect.intersectees[0]);
          break;
        default:
          break;
      }
      throw void 0;
    };
    var identity = function(s) {
      return s;
    };
    var revivers = {
      string: [function(s) {
        return globalThis.String(s);
      }, ".*"],
      number: [
        function(s) {
          return globalThis.Number(s);
        },
        "[+-]?(?:\\d*\\.\\d+|\\d+\\.\\d*|\\d+)(?:[Ee][+-]?\\d+)?",
        "0[Bb][01]+",
        "0[Oo][0-7]+",
        "0[Xx][0-9A-Fa-f]+"
        // Note: `"NaN"` isn't here, as TS doesn't allow `"NaN"` to be a `` `${number}` ``
      ],
      bigint: [function(s) {
        return globalThis.BigInt(s);
      }, "-?[1-9]d*"],
      boolean: [function(s) {
        return s === "false" ? false : true;
      }, "true", "false"],
      null: [function() {
        return null;
      }, "null"],
      undefined: [function() {
        return void 0;
      }, "undefined"]
    };
    var getReviversFor = function(reflect) {
      switch (reflect.tag) {
        case "literal": {
          var _a = __read(revivers[(0, util_1.typeOf)(reflect.value)] || [identity], 1), reviver_1 = _a[0];
          return reviver_1;
        }
        case "brand":
          return getReviversFor(reflect.entity);
        case "constraint":
          return getReviversFor(reflect.underlying);
        case "union":
          return reflect.alternatives.map(getReviversFor);
        case "intersect":
          return reflect.intersectees.map(getReviversFor);
        default:
          var _b = __read(revivers[reflect.tag] || [identity], 1), reviver = _b[0];
          return reviver;
      }
    };
    var reviveValidate = function(reflect, visited) {
      return function(value) {
        var e_1, _a, e_2, _b;
        var revivers2 = getReviversFor(reflect);
        if (Array.isArray(revivers2)) {
          switch (reflect.tag) {
            case "union":
              try {
                for (var _c = __values(reflect.alternatives), _d = _c.next(); !_d.done; _d = _c.next()) {
                  var alternative = _d.value;
                  var validated = reviveValidate(alternative.reflect, visited)(value);
                  if (validated.success)
                    return validated;
                }
              } catch (e_1_1) {
                e_1 = { error: e_1_1 };
              } finally {
                try {
                  if (_d && !_d.done && (_a = _c.return))
                    _a.call(_c);
                } finally {
                  if (e_1)
                    throw e_1.error;
                }
              }
              return util_1.FAILURE.TYPE_INCORRECT(reflect, value);
            case "intersect":
              try {
                for (var _e = __values(reflect.intersectees), _f = _e.next(); !_f.done; _f = _e.next()) {
                  var intersectee = _f.value;
                  var validated = reviveValidate(intersectee.reflect, visited)(value);
                  if (!validated.success)
                    return validated;
                }
              } catch (e_2_1) {
                e_2 = { error: e_2_1 };
              } finally {
                try {
                  if (_f && !_f.done && (_b = _e.return))
                    _b.call(_e);
                } finally {
                  if (e_2)
                    throw e_2.error;
                }
              }
              return (0, util_1.SUCCESS)(value);
            default:
              throw Error("impossible");
          }
        } else {
          var reviver = revivers2;
          var validated = (0, runtype_1.innerValidate)(reflect, reviver(value), visited);
          if (!validated.success && validated.code === "VALUE_INCORRECT" && reflect.tag === "literal")
            return util_1.FAILURE.VALUE_INCORRECT("literal", '"'.concat((0, literal_1.literal)(reflect.value), '"'), '"'.concat(value, '"'));
          return validated;
        }
      };
    };
    var getRegExpPatternFor = function(reflect) {
      switch (reflect.tag) {
        case "literal":
          return escapeRegExp(String(reflect.value));
        case "brand":
          return getRegExpPatternFor(reflect.entity);
        case "constraint":
          return getRegExpPatternFor(reflect.underlying);
        case "union":
          return reflect.alternatives.map(getRegExpPatternFor).join("|");
        case "template": {
          return reflect.strings.map(escapeRegExp).reduce(function(pattern, string, i) {
            var prefix = pattern + string;
            var runtype = reflect.runtypes[i];
            if (runtype)
              return prefix + "(?:".concat(getRegExpPatternFor(runtype.reflect), ")");
            else
              return prefix;
          }, "");
        }
        default:
          var _a = __read(revivers[reflect.tag] || [void 0, ".*"]), patterns = _a.slice(1);
          return patterns.join("|");
      }
    };
    var createRegExpForTemplate = function(reflect) {
      var pattern = reflect.strings.map(escapeRegExp).reduce(function(pattern2, string, i) {
        var prefix = pattern2 + string;
        var runtype = reflect.runtypes[i];
        if (runtype)
          return prefix + "(".concat(getRegExpPatternFor(runtype.reflect), ")");
        else
          return prefix;
      }, "");
      return new RegExp("^".concat(pattern, "$"), "su");
    };
    function Template() {
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      var _a = __read(normalizeArgs(args), 2), strings = _a[0], runtypes15 = _a[1];
      var self = { tag: "template", strings, runtypes: runtypes15 };
      var regexp = createRegExpForTemplate(self);
      var test = function(value, visited) {
        var matches = value.match(regexp);
        if (matches) {
          var values = matches.slice(1);
          for (var i = 0; i < runtypes15.length; i++) {
            var runtype = runtypes15[i];
            var value_1 = values[i];
            var validated = reviveValidate(runtype.reflect, visited)(value_1);
            if (!validated.success)
              return validated;
          }
          return (0, util_1.SUCCESS)(value);
        } else {
          return util_1.FAILURE.VALUE_INCORRECT("string", "".concat((0, show_1.default)(self)), '"'.concat((0, literal_1.literal)(value), '"'));
        }
      };
      return (0, runtype_1.create)(function(value, visited) {
        if (typeof value !== "string")
          return util_1.FAILURE.TYPE_INCORRECT(self, value);
        else {
          var validated = test(value, visited);
          if (!validated.success) {
            var result = util_1.FAILURE.VALUE_INCORRECT("string", "".concat((0, show_1.default)(self)), '"'.concat(value, '"'));
            if (result.message !== validated.message)
              result.message += " (inner: ".concat(validated.message, ")");
            return result;
          } else
            return (0, util_1.SUCCESS)(value);
        }
      }, self);
    }
    exports.Template = Template;
  }
});

// ../../node_modules/runtypes/lib/types/boolean.js
var require_boolean = __commonJS({
  "../../node_modules/runtypes/lib/types/boolean.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Boolean = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "boolean" };
    exports.Boolean = (0, runtype_1.create)(function(value) {
      return typeof value === "boolean" ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
    }, self);
  }
});

// ../../node_modules/runtypes/lib/types/number.js
var require_number = __commonJS({
  "../../node_modules/runtypes/lib/types/number.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Number = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "number" };
    exports.Number = (0, runtype_1.create)(function(value) {
      return typeof value === "number" ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
    }, self);
  }
});

// ../../node_modules/runtypes/lib/types/bigint.js
var require_bigint = __commonJS({
  "../../node_modules/runtypes/lib/types/bigint.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.BigInt = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "bigint" };
    exports.BigInt = (0, runtype_1.create)(function(value) {
      return typeof value === "bigint" ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
    }, self);
  }
});

// ../../node_modules/runtypes/lib/types/string.js
var require_string = __commonJS({
  "../../node_modules/runtypes/lib/types/string.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.String = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "string" };
    exports.String = (0, runtype_1.create)(function(value) {
      return typeof value === "string" ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
    }, self);
  }
});

// ../../node_modules/runtypes/lib/types/symbol.js
var require_symbol = __commonJS({
  "../../node_modules/runtypes/lib/types/symbol.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Symbol = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var f = function(key) {
      var self2 = { tag: "symbol", key };
      return (0, runtype_1.create)(function(value) {
        if (typeof value !== "symbol")
          return util_1.FAILURE.TYPE_INCORRECT(self2, value);
        else {
          var keyForValue = globalThis.Symbol.keyFor(value);
          if (keyForValue !== key)
            return util_1.FAILURE.VALUE_INCORRECT("symbol key", quoteIfPresent(key), quoteIfPresent(keyForValue));
          else
            return (0, util_1.SUCCESS)(value);
        }
      }, self2);
    };
    var self = { tag: "symbol" };
    exports.Symbol = (0, runtype_1.create)(function(value) {
      return typeof value === "symbol" ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
    }, Object.assign(f, self));
    var quoteIfPresent = function(key) {
      return key === void 0 ? "undefined" : '"'.concat(key, '"');
    };
  }
});

// ../../node_modules/runtypes/lib/types/array.js
var require_array = __commonJS({
  "../../node_modules/runtypes/lib/types/array.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Array = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function InternalArr(element, isReadonly) {
      var self = { tag: "array", isReadonly, element };
      return withExtraModifierFuncs((0, runtype_1.create)(function(xs, visited) {
        if (!Array.isArray(xs))
          return util_1.FAILURE.TYPE_INCORRECT(self, xs);
        var keys = (0, util_1.enumerableKeysOf)(xs);
        var results = keys.map(function(key) {
          return (0, runtype_1.innerValidate)(element, xs[key], visited);
        });
        var details = keys.reduce(function(details2, key) {
          var result = results[key];
          if (!result.success)
            details2[key] = result.details || result.message;
          return details2;
        }, []);
        if ((0, util_1.enumerableKeysOf)(details).length !== 0)
          return util_1.FAILURE.CONTENT_INCORRECT(self, details);
        else
          return (0, util_1.SUCCESS)(xs);
      }, self));
    }
    function Arr(element) {
      return InternalArr(element, false);
    }
    exports.Array = Arr;
    function withExtraModifierFuncs(A) {
      A.asReadonly = asReadonly;
      return A;
      function asReadonly() {
        return InternalArr(A.element, true);
      }
    }
  }
});

// ../../node_modules/runtypes/lib/types/tuple.js
var require_tuple = __commonJS({
  "../../node_modules/runtypes/lib/types/tuple.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Tuple = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function Tuple() {
      var components = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        components[_i] = arguments[_i];
      }
      var self = { tag: "tuple", components };
      return (0, runtype_1.create)(function(xs, visited) {
        if (!Array.isArray(xs))
          return util_1.FAILURE.TYPE_INCORRECT(self, xs);
        if (xs.length !== components.length)
          return util_1.FAILURE.CONSTRAINT_FAILED(self, "Expected length ".concat(components.length, ", but was ").concat(xs.length));
        var keys = (0, util_1.enumerableKeysOf)(xs);
        var results = keys.map(function(key) {
          return (0, runtype_1.innerValidate)(components[key], xs[key], visited);
        });
        var details = keys.reduce(function(details2, key) {
          var result = results[key];
          if (!result.success)
            details2[key] = result.details || result.message;
          return details2;
        }, []);
        if ((0, util_1.enumerableKeysOf)(details).length !== 0)
          return util_1.FAILURE.CONTENT_INCORRECT(self, details);
        else
          return (0, util_1.SUCCESS)(xs);
      }, self);
    }
    exports.Tuple = Tuple;
  }
});

// ../../node_modules/runtypes/lib/types/record.js
var require_record = __commonJS({
  "../../node_modules/runtypes/lib/types/record.js"(exports) {
    "use strict";
    var __read = exports && exports.__read || function(o, n) {
      var m = typeof Symbol === "function" && o[Symbol.iterator];
      if (!m)
        return o;
      var i = m.call(o), r, ar = [], e;
      try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done)
          ar.push(r.value);
      } catch (error) {
        e = { error };
      } finally {
        try {
          if (r && !r.done && (m = i["return"]))
            m.call(i);
        } finally {
          if (e)
            throw e.error;
        }
      }
      return ar;
    };
    var __spreadArray = exports && exports.__spreadArray || function(to, from, pack) {
      if (pack || arguments.length === 2)
        for (var i = 0, l = from.length, ar; i < l; i++) {
          if (ar || !(i in from)) {
            if (!ar)
              ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
          }
        }
      return to.concat(ar || Array.prototype.slice.call(from));
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Partial = exports.Record = exports.InternalRecord = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function InternalRecord(fields, isPartial, isReadonly) {
      var self = { tag: "record", isPartial, isReadonly, fields };
      return withExtraModifierFuncs((0, runtype_1.create)(function(x, visited) {
        if (x === null || x === void 0) {
          return util_1.FAILURE.TYPE_INCORRECT(self, x);
        }
        var keysOfFields = (0, util_1.enumerableKeysOf)(fields);
        if (keysOfFields.length !== 0 && typeof x !== "object")
          return util_1.FAILURE.TYPE_INCORRECT(self, x);
        var keys = __spreadArray([], __read(new Set(__spreadArray(__spreadArray([], __read(keysOfFields), false), __read((0, util_1.enumerableKeysOf)(x)), false))), false);
        var results = keys.reduce(function(results2, key) {
          var fieldsHasKey = (0, util_1.hasKey)(key, fields);
          var xHasKey = (0, util_1.hasKey)(key, x);
          if (fieldsHasKey) {
            var runtype = fields[key];
            var isOptional = isPartial || runtype.reflect.tag === "optional";
            if (xHasKey) {
              var value = x[key];
              if (isOptional && value === void 0)
                results2[key] = (0, util_1.SUCCESS)(value);
              else
                results2[key] = (0, runtype_1.innerValidate)(runtype, value, visited);
            } else {
              if (!isOptional)
                results2[key] = util_1.FAILURE.PROPERTY_MISSING(runtype.reflect);
              else
                results2[key] = (0, util_1.SUCCESS)(void 0);
            }
          } else if (xHasKey) {
            var value = x[key];
            results2[key] = (0, util_1.SUCCESS)(value);
          } else {
            throw new Error("impossible");
          }
          return results2;
        }, {});
        var details = keys.reduce(function(details2, key) {
          var result = results[key];
          if (!result.success)
            details2[key] = result.details || result.message;
          return details2;
        }, {});
        if ((0, util_1.enumerableKeysOf)(details).length !== 0)
          return util_1.FAILURE.CONTENT_INCORRECT(self, details);
        else
          return (0, util_1.SUCCESS)(x);
      }, self));
    }
    exports.InternalRecord = InternalRecord;
    function Record11(fields) {
      return InternalRecord(fields, false, false);
    }
    exports.Record = Record11;
    function Partial2(fields) {
      return InternalRecord(fields, true, false);
    }
    exports.Partial = Partial2;
    function withExtraModifierFuncs(A) {
      A.asPartial = asPartial;
      A.asReadonly = asReadonly;
      A.pick = pick;
      A.omit = omit2;
      A.extend = extend;
      return A;
      function asPartial() {
        return InternalRecord(A.fields, true, A.isReadonly);
      }
      function asReadonly() {
        return InternalRecord(A.fields, A.isPartial, true);
      }
      function pick() {
        var keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          keys[_i] = arguments[_i];
        }
        var result = {};
        keys.forEach(function(key) {
          result[key] = A.fields[key];
        });
        return InternalRecord(result, A.isPartial, A.isReadonly);
      }
      function omit2() {
        var keys = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          keys[_i] = arguments[_i];
        }
        var result = {};
        var existingKeys = (0, util_1.enumerableKeysOf)(A.fields);
        existingKeys.forEach(function(key) {
          if (!keys.includes(key))
            result[key] = A.fields[key];
        });
        return InternalRecord(result, A.isPartial, A.isReadonly);
      }
      function extend(fields) {
        return InternalRecord(Object.assign({}, A.fields, fields), A.isPartial, A.isReadonly);
      }
    }
  }
});

// ../../node_modules/runtypes/lib/types/constraint.js
var require_constraint = __commonJS({
  "../../node_modules/runtypes/lib/types/constraint.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Guard = exports.Constraint = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var unknown_1 = require_unknown();
    function Constraint(underlying, constraint, options) {
      var name = options && options.name;
      var args = options && options.args;
      var self = {
        tag: "constraint",
        underlying,
        constraint,
        name,
        args
      };
      return (0, runtype_1.create)(function(value) {
        var result = underlying.validate(value);
        if (!result.success)
          return result;
        var message = constraint(result.value);
        if (typeof message === "string")
          return util_1.FAILURE.CONSTRAINT_FAILED(self, message);
        else if (!message)
          return util_1.FAILURE.CONSTRAINT_FAILED(self);
        return (0, util_1.SUCCESS)(result.value);
      }, self);
    }
    exports.Constraint = Constraint;
    var Guard = function(guard, options) {
      return unknown_1.Unknown.withGuard(guard, options);
    };
    exports.Guard = Guard;
  }
});

// ../../node_modules/runtypes/lib/types/dictionary.js
var require_dictionary = __commonJS({
  "../../node_modules/runtypes/lib/types/dictionary.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Dictionary = void 0;
    var runtype_1 = require_runtype();
    var string_1 = require_string();
    var constraint_1 = require_constraint();
    var show_1 = require_show();
    var util_1 = require_util();
    var NumberKey = (0, constraint_1.Constraint)(string_1.String, function(s) {
      return !isNaN(+s);
    }, { name: "number" });
    function Dictionary4(value, key) {
      var keyRuntype = key === void 0 ? string_1.String : key === "string" ? string_1.String : key === "number" ? NumberKey : key;
      var keyString = (0, show_1.default)(keyRuntype);
      var self = { tag: "dictionary", key: keyString, value };
      return (0, runtype_1.create)(function(x, visited) {
        if (x === null || x === void 0 || typeof x !== "object")
          return util_1.FAILURE.TYPE_INCORRECT(self, x);
        if (Object.getPrototypeOf(x) !== Object.prototype) {
          if (!Array.isArray(x) || keyString === "string")
            return util_1.FAILURE.TYPE_INCORRECT(self, x);
        }
        var numberString = /^(?:NaN|-?\d+(?:\.\d+)?)$/;
        var keys = (0, util_1.enumerableKeysOf)(x);
        var results = keys.reduce(function(results2, key2) {
          var isNumberLikeKey = typeof key2 === "string" && numberString.test(key2);
          var keyInterop = isNumberLikeKey ? globalThis.Number(key2) : key2;
          if (isNumberLikeKey ? !keyRuntype.guard(keyInterop) && !keyRuntype.guard(key2) : !keyRuntype.guard(keyInterop)) {
            results2[key2] = util_1.FAILURE.KEY_INCORRECT(self, keyRuntype.reflect, keyInterop);
          } else
            results2[key2] = (0, runtype_1.innerValidate)(value, x[key2], visited);
          return results2;
        }, {});
        var details = keys.reduce(function(details2, key2) {
          var result = results[key2];
          if (!result.success)
            details2[key2] = result.details || result.message;
          return details2;
        }, {});
        if ((0, util_1.enumerableKeysOf)(details).length !== 0)
          return util_1.FAILURE.CONTENT_INCORRECT(self, details);
        else
          return (0, util_1.SUCCESS)(x);
      }, self);
    }
    exports.Dictionary = Dictionary4;
  }
});

// ../../node_modules/runtypes/lib/types/intersect.js
var require_intersect = __commonJS({
  "../../node_modules/runtypes/lib/types/intersect.js"(exports) {
    "use strict";
    var __values = exports && exports.__values || function(o) {
      var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
      if (m)
        return m.call(o);
      if (o && typeof o.length === "number")
        return {
          next: function() {
            if (o && i >= o.length)
              o = void 0;
            return { value: o && o[i++], done: !o };
          }
        };
      throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Intersect = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function Intersect() {
      var intersectees = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        intersectees[_i] = arguments[_i];
      }
      var self = { tag: "intersect", intersectees };
      return (0, runtype_1.create)(function(value, visited) {
        var e_1, _a;
        try {
          for (var intersectees_1 = __values(intersectees), intersectees_1_1 = intersectees_1.next(); !intersectees_1_1.done; intersectees_1_1 = intersectees_1.next()) {
            var targetType = intersectees_1_1.value;
            var result = (0, runtype_1.innerValidate)(targetType, value, visited);
            if (!result.success)
              return result;
          }
        } catch (e_1_1) {
          e_1 = { error: e_1_1 };
        } finally {
          try {
            if (intersectees_1_1 && !intersectees_1_1.done && (_a = intersectees_1.return))
              _a.call(intersectees_1);
          } finally {
            if (e_1)
              throw e_1.error;
          }
        }
        return (0, util_1.SUCCESS)(value);
      }, self);
    }
    exports.Intersect = Intersect;
  }
});

// ../../node_modules/runtypes/lib/types/optional.js
var require_optional = __commonJS({
  "../../node_modules/runtypes/lib/types/optional.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Optional = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function Optional2(runtype) {
      var self = { tag: "optional", underlying: runtype };
      return (0, runtype_1.create)(function(value) {
        return value === void 0 ? (0, util_1.SUCCESS)(value) : runtype.validate(value);
      }, self);
    }
    exports.Optional = Optional2;
  }
});

// ../../node_modules/runtypes/lib/types/function.js
var require_function = __commonJS({
  "../../node_modules/runtypes/lib/types/function.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Function = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    var self = { tag: "function" };
    exports.Function = (0, runtype_1.create)(function(value) {
      return typeof value === "function" ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
    }, self);
  }
});

// ../../node_modules/runtypes/lib/types/instanceof.js
var require_instanceof = __commonJS({
  "../../node_modules/runtypes/lib/types/instanceof.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.InstanceOf = void 0;
    var runtype_1 = require_runtype();
    var util_1 = require_util();
    function InstanceOf(ctor) {
      var self = { tag: "instanceof", ctor };
      return (0, runtype_1.create)(function(value) {
        return value instanceof ctor ? (0, util_1.SUCCESS)(value) : util_1.FAILURE.TYPE_INCORRECT(self, value);
      }, self);
    }
    exports.InstanceOf = InstanceOf;
  }
});

// ../../node_modules/runtypes/lib/types/lazy.js
var require_lazy = __commonJS({
  "../../node_modules/runtypes/lib/types/lazy.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Lazy = void 0;
    var runtype_1 = require_runtype();
    function Lazy(delayed) {
      var data = {
        get tag() {
          return getWrapped()["tag"];
        }
      };
      var cached;
      function getWrapped() {
        if (!cached) {
          cached = delayed();
          for (var k in cached)
            if (k !== "tag")
              data[k] = cached[k];
        }
        return cached;
      }
      return (0, runtype_1.create)(function(x) {
        return getWrapped().validate(x);
      }, data);
    }
    exports.Lazy = Lazy;
  }
});

// ../../node_modules/runtypes/lib/types/brand.js
var require_brand = __commonJS({
  "../../node_modules/runtypes/lib/types/brand.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.Brand = void 0;
    var runtype_1 = require_runtype();
    function Brand(brand, entity) {
      var self = { tag: "brand", brand, entity };
      return (0, runtype_1.create)(function(value) {
        return entity.validate(value);
      }, self);
    }
    exports.Brand = Brand;
  }
});

// ../../node_modules/runtypes/lib/decorator.js
var require_decorator = __commonJS({
  "../../node_modules/runtypes/lib/decorator.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.checked = exports.check = void 0;
    var errors_1 = require_errors();
    var util_1 = require_util();
    var prototypes = /* @__PURE__ */ new WeakMap();
    function check(target, propertyKey, parameterIndex) {
      var prototype = prototypes.get(target) || /* @__PURE__ */ new Map();
      prototypes.set(target, prototype);
      var validParameterIndices = prototype.get(propertyKey) || [];
      prototype.set(propertyKey, validParameterIndices);
      validParameterIndices.push(parameterIndex);
    }
    exports.check = check;
    function getValidParameterIndices(target, propertyKey, runtypeCount) {
      var prototype = prototypes.get(target);
      var validParameterIndices = prototype && prototype.get(propertyKey);
      if (validParameterIndices) {
        return validParameterIndices;
      }
      var indices = [];
      for (var i = 0; i < runtypeCount; i++) {
        indices.push(i);
      }
      return indices;
    }
    function checked() {
      var runtypes15 = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        runtypes15[_i] = arguments[_i];
      }
      if (runtypes15.length === 0) {
        throw new Error("No runtype provided to `@checked`. Please remove the decorator.");
      }
      return function(target, propertyKey, descriptor) {
        var method = descriptor.value;
        var methodId = (target.name || target.constructor.name + ".prototype") + (typeof propertyKey === "string" ? '["'.concat(propertyKey, '"]') : "[".concat(String(propertyKey), "]"));
        var validParameterIndices = getValidParameterIndices(target, propertyKey, runtypes15.length);
        if (validParameterIndices.length !== runtypes15.length) {
          throw new Error("Number of `@checked` runtypes and @check parameters not matched.");
        }
        if (validParameterIndices.length > method.length) {
          throw new Error("Number of `@checked` runtypes exceeds actual parameter length.");
        }
        descriptor.value = function() {
          var args = [];
          for (var _i2 = 0; _i2 < arguments.length; _i2++) {
            args[_i2] = arguments[_i2];
          }
          runtypes15.forEach(function(type, typeIndex) {
            var parameterIndex = validParameterIndices[typeIndex];
            var result = type.validate(args[parameterIndex]);
            if (!result.success) {
              var message = "".concat(methodId, ", argument #").concat(parameterIndex, ": ").concat(result.message);
              var failure = util_1.FAILURE.ARGUMENT_INCORRECT(message);
              throw new errors_1.ValidationError(failure);
            }
          });
          return method.apply(this, args);
        };
      };
    }
    exports.checked = checked;
  }
});

// ../../node_modules/runtypes/lib/index.js
var require_lib = __commonJS({
  "../../node_modules/runtypes/lib/index.js"(exports) {
    "use strict";
    var __createBinding = exports && exports.__createBinding || (Object.create ? function(o, m, k, k2) {
      if (k2 === void 0)
        k2 = k;
      Object.defineProperty(o, k2, { enumerable: true, get: function() {
        return m[k];
      } });
    } : function(o, m, k, k2) {
      if (k2 === void 0)
        k2 = k;
      o[k2] = m[k];
    });
    var __exportStar = exports && exports.__exportStar || function(m, exports2) {
      for (var p in m)
        if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports2, p))
          __createBinding(exports2, m, p);
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.InstanceOf = exports.Nullish = exports.Null = exports.Undefined = exports.Literal = void 0;
    __exportStar(require_reflect(), exports);
    __exportStar(require_result(), exports);
    __exportStar(require_contract(), exports);
    __exportStar(require_asynccontract(), exports);
    __exportStar(require_match(), exports);
    __exportStar(require_errors(), exports);
    __exportStar(require_unknown(), exports);
    __exportStar(require_never(), exports);
    __exportStar(require_void(), exports);
    var literal_1 = require_literal();
    Object.defineProperty(exports, "Literal", { enumerable: true, get: function() {
      return literal_1.Literal;
    } });
    Object.defineProperty(exports, "Undefined", { enumerable: true, get: function() {
      return literal_1.Undefined;
    } });
    Object.defineProperty(exports, "Null", { enumerable: true, get: function() {
      return literal_1.Null;
    } });
    Object.defineProperty(exports, "Nullish", { enumerable: true, get: function() {
      return literal_1.Nullish;
    } });
    __exportStar(require_template(), exports);
    __exportStar(require_boolean(), exports);
    __exportStar(require_number(), exports);
    __exportStar(require_bigint(), exports);
    __exportStar(require_string(), exports);
    __exportStar(require_symbol(), exports);
    __exportStar(require_array(), exports);
    __exportStar(require_tuple(), exports);
    __exportStar(require_record(), exports);
    __exportStar(require_dictionary(), exports);
    __exportStar(require_union(), exports);
    __exportStar(require_intersect(), exports);
    __exportStar(require_optional(), exports);
    __exportStar(require_function(), exports);
    var instanceof_1 = require_instanceof();
    Object.defineProperty(exports, "InstanceOf", { enumerable: true, get: function() {
      return instanceof_1.InstanceOf;
    } });
    __exportStar(require_lazy(), exports);
    __exportStar(require_constraint(), exports);
    __exportStar(require_brand(), exports);
    __exportStar(require_decorator(), exports);
  }
});

// src/index.ts
var src_exports = {};
__export(src_exports, {
  data: () => data_exports,
  lib: () => lib_exports
});
module.exports = __toCommonJS(src_exports);

// src/data/index.ts
var data_exports = {};
__export(data_exports, {
  appConfig: () => appConfig_exports,
  getSessionHubspotContactInput: () => getSessionHubspotContactInput_exports,
  getSessionHubspotContactOutput: () => getSessionHubspotContactOutput_exports,
  hubspotApiSubmitFormFieldErrorType: () => hubspotApiSubmitFormFieldErrorType_exports,
  hubspotContact: () => hubspotContact_exports,
  hubspotContactGetInput: () => hubspotContactGetInput_exports,
  hubspotContactGetOutput: () => hubspotContactGetOutput_exports,
  hubspotFormFieldObjectTypeId: () => hubspotFormFieldObjectTypeId_exports,
  hubspotFormSubmitErrorDetail: () => hubspotFormSubmitErrorDetail_exports,
  hubspotFormSubmitFormValidationErrorDetail: () => hubspotFormSubmitFormValidationErrorDetail_exports,
  hubspotFormSubmitInput: () => hubspotFormSubmitInput_exports,
  hubspotFormSubmitInputField: () => hubspotFormSubmitInputField_exports,
  hubspotFormSubmitInputFields: () => hubspotFormSubmitInputFields_exports,
  logEntry: () => logEntry_exports,
  logLevel: () => logLevel_exports,
  logger: () => logger_exports,
  setSessionHubspotContactInput: () => setSessionHubspotContactInput_exports
});

// src/data/appConfig.ts
var appConfig_exports = {};
__export(appConfig_exports, {
  AppConfig: () => AppConfig
});
var runtypes = __toESM(require_lib());
var AppConfig = runtypes.Record({
  stage: runtypes.String,
  parameterStore: runtypes.Record({
    appConfigParameterPath: runtypes.String,
    hubspotApiConfigParameterPath: runtypes.String
  }),
  configs: runtypes.Record({
    serviceName: runtypes.String,
    kmsKeyIdExportName: runtypes.String,
    configPolicyArnExportName: runtypes.String
  }),
  api: runtypes.Record({
    serviceName: runtypes.String,
    serviceNameLc: runtypes.String,
    apiBaseUrlExportName: runtypes.String,
    acceptedOrigins: runtypes.Array(runtypes.String),
    sessionHubspotContactTableName: runtypes.String,
    sessionHubspotContactTableTokenAttributeName: runtypes.String,
    sessionHubspotContactTableTtlAttributeName: runtypes.String,
    sessionHubspotContactTableTtlSeconds: runtypes.Number
  }),
  web: runtypes.Partial({
    mtWebpagesApiBaseUrl: runtypes.Union(runtypes.String, runtypes.Nullish),
    enableMinify: runtypes.Boolean,
    enableConsoleLogging: runtypes.Boolean,
    sentryDsn: runtypes.Union(runtypes.String, runtypes.Nullish),
    sentryEnvironment: runtypes.Union(runtypes.String, runtypes.Nullish),
    sentryTracesSampleRate: runtypes.Union(runtypes.Number, runtypes.Nullish),
    sentryMinimumLogLevel: runtypes.Union(runtypes.String, runtypes.Nullish)
  }),
  webCdn: runtypes.Record({
    serviceName: runtypes.String,
    serviceNameLc: runtypes.String,
    cacheMaxTtl: runtypes.Number
  })
});

// src/data/getSessionHubspotContactInput.ts
var getSessionHubspotContactInput_exports = {};
__export(getSessionHubspotContactInput_exports, {
  GetSessionHubspotContactInput: () => GetSessionHubspotContactInput
});
var runtypes2 = __toESM(require_lib());
var GetSessionHubspotContactInput = runtypes2.Record({
  token: runtypes2.String,
  properties: runtypes2.Array(runtypes2.String)
});

// src/data/getSessionHubspotContactOutput.ts
var getSessionHubspotContactOutput_exports = {};
__export(getSessionHubspotContactOutput_exports, {
  GetSessionHubspotContactOutput: () => GetSessionHubspotContactOutput
});
var runtypes4 = __toESM(require_lib());

// src/data/hubspotContact.ts
var hubspotContact_exports = {};
__export(hubspotContact_exports, {
  HubspotContact: () => HubspotContact,
  HubspotContactPropertyValue: () => HubspotContactPropertyValue,
  fromHubspotFormFields: () => fromHubspotFormFields
});
var runtypes3 = __toESM(require_lib());
var HubspotContactPropertyValue = runtypes3.Union(runtypes3.String, runtypes3.Number, runtypes3.Boolean);
var HubspotContact = runtypes3.Record({
  properties: runtypes3.Dictionary(
    runtypes3.Record({
      value: HubspotContactPropertyValue
    }),
    runtypes3.String
  )
});
var fromHubspotFormFields = (fields) => ({
  properties: fields.reduce((acc, field) => ({ ...acc, [field.name]: field.value }), {})
});

// src/data/getSessionHubspotContactOutput.ts
var GetSessionHubspotContactOutput = runtypes4.Record({
  contact: HubspotContact
});

// src/data/hubspotApiSubmitFormFieldErrorType.ts
var hubspotApiSubmitFormFieldErrorType_exports = {};
__export(hubspotApiSubmitFormFieldErrorType_exports, {
  HubspotApiSubmitFormFieldErrorType: () => HubspotApiSubmitFormFieldErrorType
});
var runtypes5 = __toESM(require_lib());
var HubspotApiSubmitFormFieldErrorType = runtypes5.Union(
  runtypes5.Literal("BLOCKED_EMAIL"),
  runtypes5.Literal("REQUIRED_FIELD"),
  runtypes5.Literal("INPUT_TOO_LARGE")
);

// src/data/hubspotContactGetInput.ts
var hubspotContactGetInput_exports = {};
__export(hubspotContactGetInput_exports, {
  HubspotContactGetInput: () => HubspotContactGetInput
});
var runtypes6 = __toESM(require_lib());
var HubspotContactGetInput = runtypes6.Record({
  hutk: runtypes6.String,
  properties: runtypes6.Array(runtypes6.String)
});

// src/data/hubspotContactGetOutput.ts
var hubspotContactGetOutput_exports = {};
__export(hubspotContactGetOutput_exports, {
  HubspotContactGetOutput: () => HubspotContactGetOutput
});
var HubspotContactGetOutput = HubspotContact;

// src/data/hubspotFormFieldObjectTypeId.ts
var hubspotFormFieldObjectTypeId_exports = {};
__export(hubspotFormFieldObjectTypeId_exports, {
  HubspotFormFieldObjectTypeId: () => HubspotFormFieldObjectTypeId
});
var runtypes7 = __toESM(require_lib());
var HubspotFormFieldObjectTypeId = runtypes7.Union(
  runtypes7.Literal("0-1"),
  runtypes7.Literal("0-2"),
  runtypes7.Literal("0-3"),
  runtypes7.Literal("0-5")
);

// src/data/hubspotFormSubmitFormValidationErrorDetail.ts
var hubspotFormSubmitFormValidationErrorDetail_exports = {};
__export(hubspotFormSubmitFormValidationErrorDetail_exports, {
  HubspotFormSubmitFormValidationErrorDetail: () => HubspotFormSubmitFormValidationErrorDetail
});
var runtypes8 = __toESM(require_lib());
var HubspotFormSubmitFormValidationErrorDetail = runtypes8.Record({
  type: runtypes8.Literal("FormValidationError"),
  message: runtypes8.String,
  formErrors: runtypes8.Array(runtypes8.String),
  fieldErrors: runtypes8.Dictionary(
    runtypes8.Array(HubspotApiSubmitFormFieldErrorType),
    runtypes8.String
  )
});

// src/data/hubspotFormSubmitErrorDetail.ts
var hubspotFormSubmitErrorDetail_exports = {};
__export(hubspotFormSubmitErrorDetail_exports, {
  HubspotFormSubmitErrorDetail: () => HubspotFormSubmitErrorDetail
});
var runtypes9 = __toESM(require_lib());
var HubspotFormSubmitErrorDetail = runtypes9.Union(
  runtypes9.Record({
    type: runtypes9.Literal("RequestParseError"),
    message: runtypes9.String
  }),
  runtypes9.Record({
    type: runtypes9.Literal("RequestValidationError"),
    message: runtypes9.String
  }),
  HubspotFormSubmitFormValidationErrorDetail,
  runtypes9.Record({
    type: runtypes9.Literal("UnhandledError")
  })
);

// src/data/hubspotFormSubmitInputFields.ts
var hubspotFormSubmitInputFields_exports = {};
__export(hubspotFormSubmitInputFields_exports, {
  HubspotFormSubmitInputFields: () => HubspotFormSubmitInputFields
});
var runtypes11 = __toESM(require_lib());

// src/data/hubspotFormSubmitInputField.ts
var hubspotFormSubmitInputField_exports = {};
__export(hubspotFormSubmitInputField_exports, {
  HubspotFormSubmitInputField: () => HubspotFormSubmitInputField
});
var runtypes10 = __toESM(require_lib());
var HubspotFormSubmitInputField = runtypes10.Record({
  name: runtypes10.String,
  value: runtypes10.String,
  objectTypeId: HubspotFormFieldObjectTypeId
});

// src/data/hubspotFormSubmitInputFields.ts
var HubspotFormSubmitInputFields = runtypes11.Dictionary(
  HubspotFormSubmitInputField,
  runtypes11.String
);

// src/data/hubspotFormSubmitInput.ts
var hubspotFormSubmitInput_exports = {};
__export(hubspotFormSubmitInput_exports, {
  HubspotFormSubmitInput: () => HubspotFormSubmitInput
});
var runtypes12 = __toESM(require_lib());
var HubspotFormSubmitInput = runtypes12.Record({
  formId: runtypes12.String,
  submittedAt: runtypes12.Number,
  context: runtypes12.Record({
    hutk: runtypes12.Optional(runtypes12.String),
    pageUri: runtypes12.String,
    pageName: runtypes12.String
  }),
  fields: HubspotFormSubmitInputFields
});

// src/data/setSessionHubspotContactInput.ts
var setSessionHubspotContactInput_exports = {};
__export(setSessionHubspotContactInput_exports, {
  SetSessionHubspotContactInput: () => SetSessionHubspotContactInput
});
var runtypes13 = __toESM(require_lib());
var SetSessionHubspotContactInput = runtypes13.Record({
  token: runtypes13.String,
  contact: HubspotContact
});

// src/data/logLevel.ts
var logLevel_exports = {};
__export(logLevel_exports, {
  LogLevel: () => LogLevel,
  LogLevelOrd: () => LogLevelOrd
});
var runtypes14 = __toESM(require_lib());
var LogLevel = runtypes14.Union(
  runtypes14.Literal("log"),
  runtypes14.Literal("info"),
  runtypes14.Literal("warning"),
  runtypes14.Literal("error")
);
var LogLevelOrd = /* @__PURE__ */ ((LogLevelOrd2) => {
  LogLevelOrd2[LogLevelOrd2["log"] = 0] = "log";
  LogLevelOrd2[LogLevelOrd2["info"] = 1] = "info";
  LogLevelOrd2[LogLevelOrd2["warning"] = 2] = "warning";
  LogLevelOrd2[LogLevelOrd2["error"] = 3] = "error";
  return LogLevelOrd2;
})(LogLevelOrd || {});

// src/data/logEntry.ts
var logEntry_exports = {};
__export(logEntry_exports, {
  addContext: () => addContext,
  addTags: () => addTags
});
var addTags = (tags) => (entry) => {
  return {
    ...entry,
    tags: {
      ...tags,
      ...entry.tags
    }
  };
};
var addContext = (context) => (entry) => {
  return {
    ...entry,
    context: {
      ...context,
      ...entry.context
    }
  };
};

// src/data/logger.ts
var logger_exports = {};
__export(logger_exports, {
  filter: () => filter,
  merge: () => merge,
  pipe: () => pipe,
  toConsoleLogger: () => toConsoleLogger
});
var pipe = (fn) => (logger) => {
  return (x) => logger(fn(x));
};
var merge = (loggers) => {
  return (x) => loggers.forEach((fn) => fn(x));
};
var filter = (pred, logger) => {
  return (x) => pred(x) ? logger(x) : null;
};
var toConsoleLogger = () => merge([
  filter(
    (x) => x.level === "log",
    (x) => console.log(x)
  ),
  filter(
    (x) => x.level === "info",
    (x) => console.info(x)
  ),
  filter(
    (x) => x.level === "warning",
    (x) => console.warn(x)
  ),
  filter(
    (x) => x.level === "error",
    (x) => console.error(x)
  )
]);

// src/lib/index.ts
var lib_exports = {};
__export(lib_exports, {
  array: () => array_exports,
  function: () => function_exports,
  record: () => record_exports,
  runtypes: () => runtypes_exports
});

// src/lib/runtypes/index.ts
var runtypes_exports = {};
__export(runtypes_exports, {
  result: () => result_exports
});

// src/lib/runtypes/result.ts
var result_exports = {};
__export(result_exports, {
  toNullable: () => toNullable
});
var toNullable = (result) => {
  return result.success ? result.value : null;
};

// src/lib/function.ts
var function_exports = {};
__export(function_exports, {
  absurd: () => absurd
});
var absurd = (_) => {
  throw new Error("absurd");
};

// src/lib/array.ts
var array_exports = {};
__export(array_exports, {
  filterMap: () => filterMap,
  groupBy: () => groupBy,
  nub: () => nub
});
var filterMap = (fn, xs) => {
  return xs.reduce((acc, x) => {
    const mx = fn(x);
    if (mx != null) {
      acc.push(mx);
    }
    return acc;
  }, []);
};
var groupBy = (fn, xs) => {
  return xs.reduce((acc, x) => {
    const key = fn(x);
    if (key in acc) {
      acc[key].push(x);
    } else {
      acc[key] = [x];
    }
    return acc;
  }, {});
};
var nub = (xs) => {
  return Array.from(new Set(xs));
};

// src/lib/record.ts
var record_exports = {};
__export(record_exports, {
  filterMap: () => filterMap2,
  filterMapWithKey: () => filterMapWithKey,
  map: () => map,
  omit: () => omit
});
var map = (fn, rx) => {
  const out = {};
  for (const k in rx) {
    out[k] = fn(rx[k]);
  }
  return out;
};
var filterMapWithKey = (fn, rx) => {
  const out = {};
  for (const k in rx) {
    const v = fn(k, rx[k]);
    if (v !== null) {
      out[k] = v;
    }
  }
  return out;
};
var filterMap2 = (fn, rx) => {
  return filterMapWithKey((_, v) => fn(v), rx);
};
var omit = (keys, rx) => {
  const set = new Set(keys);
  return filterMapWithKey((k, v) => set.has(k) ? null : v, rx);
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  data,
  lib
});
