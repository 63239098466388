export type Callback<A> = (x: A) => void

export const merge = <A>(xs: Callback<A>[]): Callback<A> => {
  return xs.reduce(concat, empty)
}

export const concat = <A>(x: Callback<A>, y: Callback<A>): Callback<A> => {
  return arg => {
    x(arg)
    y(arg)
  }
}

export const empty: Callback<unknown> = () => null
