import * as htmlElement from 'src/lib/htmlElement'

export type HubspotFormFieldContainerElement = HTMLElement

export const fromFormByFieldName = (form: HTMLFormElement, name: string): HubspotFormFieldContainerElement[] => {
  return htmlElement.fromQuerySelectorAll(`[data-field-container="${name}"]`, form)
}

export const show = (element: HubspotFormFieldContainerElement) => {
  element.style.display = 'block'
}

export const hide = (element: HubspotFormFieldContainerElement) => {
  element.style.display = 'none'
}
