import * as logger from '@mt-webpages/core/data/logger'
import * as logEntry from '@mt-webpages/core/data/logEntry'
import * as callback from 'src/data/callback'
import * as subscription from 'src/data/subscription'
import * as mtWebpagesWebEvent from 'src/data/mtWebpagesWebEvent'
import * as mtWebpagesWebContext from 'src/data/mtWebpagesWebContext'
import * as hubspotSubmitFormsScriptConfig from 'src/data/hubspotSubmitFormsScriptConfig'
import * as hubspotSubmitFormErrorDetail from 'src/data/hubspotSubmitFormErrorDetail'
import * as hubspotForms from 'src/lib/hubspotForms'

export const from =
  (config: hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfig) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): subscription.Subscription => {
    const { onFormEvent, ...rest } = config
    try {
      return hubspotForms.addFormEventListeners({
        window: context.window,
        logger: logger.pipe(logEntry.addContext(config))(context.logger),
        mtWebpagesWebSession: context.mtWebpagesWebSession,
        onFormEvent: callback.merge([onFormEvent || callback.empty, context.hubspotFormEventEmitter.emit]),
        ...context.hubspotFormConfig,
        ...hubspotSubmitFormsScriptConfig.HubspotSubmitFormsScriptConfig.check(rest)
      })
    } catch (error: unknown) {
      context.mtWebpagesWebEventEmitter.emit(
        mtWebpagesWebEvent.hubspotFormsConfigurationFailure({
          errorDetail: hubspotSubmitFormErrorDetail.fromUnknownError(error)
        })
      )
      return subscription.empty
    }
  }
