import * as hubspotSubmitFormErrorDetail from './hubspotSubmitFormErrorDetail'

export type MtWebpagesWebEvent =
  | MtWebpagesWebLoadEvent
  | MtWebpagesHubspotFormSubmitSuccessEvent
  | MtWebpagesHubspotFormConfigurationFailure
  | MtWebpagesHubspotFormsConfigurationFailure

export type MtWebpagesWebLoadEvent = {
  type: 'mtWebpagesWebLoad'
}

export type MtWebpagesHubspotFormSubmitSuccessEvent = {
  type: 'mtWebpagesWebHubspotFormSubmitSuccess'
  payload: {
    formId: string
  }
}

export type MtWebpagesHubspotFormConfigurationFailure = {
  type: 'mtWebpagesHubspotFormConfigurationFailure'
  payload: {
    formId: string
    errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
  }
}

export type MtWebpagesHubspotFormsConfigurationFailure = {
  type: 'mtWebpagesHubspotFormsConfigurationFailure'
  payload: {
    errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
  }
}

export const load: MtWebpagesWebLoadEvent = {
  type: 'mtWebpagesWebLoad'
}

export const hubspotFormSubmitSuccess = (
  payload: MtWebpagesHubspotFormSubmitSuccessEvent['payload']
): MtWebpagesHubspotFormSubmitSuccessEvent => {
  return {
    type: 'mtWebpagesWebHubspotFormSubmitSuccess',
    payload
  }
}

export const hubspotFormConfigurationFailure = (
  payload: MtWebpagesHubspotFormConfigurationFailure['payload']
): MtWebpagesHubspotFormConfigurationFailure => {
  return {
    type: 'mtWebpagesHubspotFormConfigurationFailure',
    payload
  }
}

export const hubspotFormsConfigurationFailure = (
  payload: MtWebpagesHubspotFormsConfigurationFailure['payload']
): MtWebpagesHubspotFormsConfigurationFailure => {
  return {
    type: 'mtWebpagesHubspotFormsConfigurationFailure',
    payload
  }
}
