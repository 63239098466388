export const get = (cname: string) => (window: Window) => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(window.document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim()
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}
