import * as core from '@mt-webpages/core'
import * as httpClientService from 'src/data/httpClientService'
import * as mtWebpagesHubspotFormApiSubmitError from 'src/data/mtWebpagesHubspotFormApiSubmitError'
import * as mtWebpagesHubspotFormApiSubmitErrorDetail from 'src/data/mtWebpagesHubspotFormApiSubmitErrorDetail'
import * as mtWebpagesApiClientService from 'src/data/mtWebpagesApiClientService'

export type MtWebpagesApiClientContext = {
  client: httpClientService.HttpClientService
  baseUrl: string
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
}

export const toMtWebpagesApiClientService = (
  context: MtWebpagesApiClientContext
): mtWebpagesApiClientService.MtWebpagesApiClientService => {
  return {
    postHubspotForm: input => postHubspotForm(input)(context),
    getSessionHubspotContact: input => getSessionHubspotContact(input)(context),
    setSessionHubspotContact: input => setSessionHubspotContact(input)(context)
  }
}

export const postHubspotForm =
  (input: mtWebpagesApiClientService.PostHubspotFormInput) =>
  async (context: MtWebpagesApiClientContext): Promise<void> => {
    try {
      await context.client.send(
        new Request(`${context.baseUrl}/api/hubspot/form/submit`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(input)
        })
      )
    } catch (error: unknown) {
      if (error instanceof httpClientService.HttpError && error.body) {
        throw new mtWebpagesHubspotFormApiSubmitError.MtWebpagesHubspotFormApiSubmitError(
          mtWebpagesHubspotFormApiSubmitErrorDetail.fromUnknown(error.body)
        )
      } else {
        context.logger({
          message: 'Unhandled submit error',
          level: 'warning',
          context: {
            error
          }
        })
        throw error
      }
    }
  }

export const getSessionHubspotContact =
  (input: mtWebpagesApiClientService.GetSessionHubspotContactInput) =>
  async (context: MtWebpagesApiClientContext): Promise<mtWebpagesApiClientService.GetSessionHubspotContactOutput> => {
    try {
      const result = await context.client.json(
        new Request(
          `${context.baseUrl}/api/session/hubspot/contact?${new URLSearchParams([
            ['token', input.token],
            ...input.properties.map(property => ['properties', property])
          ]).toString()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
      )
      return mtWebpagesApiClientService.GetSessionHubspotContactOutput.check(result)
    } catch (error: unknown) {
      context.logger({
        message: 'Unable to get contact',
        level: 'warning',
        context: {
          error
        }
      })
      throw error
    }
  }

export const setSessionHubspotContact =
  (input: Omit<mtWebpagesApiClientService.SetSessionHubspotContactInput, 'token'>) =>
  async (context: MtWebpagesApiClientContext): Promise<void> => {
    try {
      await context.client.json(
        new Request(`${context.baseUrl}/api/session/hubspot/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(input)
        })
      )
    } catch (error: unknown) {
      context.logger({
        message: 'Unable to set contact',
        level: 'warning',
        context: {
          error
        }
      })
      throw error
    }
  }
