import * as core from '@mt-webpages/core'
import * as eventEmitter from 'src/data/eventEmitter'
import * as mtWebpagesWebEvent from 'src/data/mtWebpagesWebEvent'
import * as hubspotFormEvent from 'src/data/hubspotFormEvent'

export type HubspotEventHandlerContext = {
  window: Window & globalThis.Window
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  mtWebpagesWebEventEmitter: eventEmitter.EventEmitter<mtWebpagesWebEvent.MtWebpagesWebEvent>
}

export const handleEvent =
  (event: hubspotFormEvent.HubspotFormEvent) => async (context: HubspotEventHandlerContext) => {
    if (event.type === 'formSubmitSuccess') {
      context.logger({
        message: 'Handling form submit success',
        level: 'info'
      })
      context.mtWebpagesWebEventEmitter.emit(
        mtWebpagesWebEvent.hubspotFormSubmitSuccess({
          formId: event.payload.formId
        })
      )
    } else if (event.type === 'formSubmitFailure') {
      context.logger({
        message: 'Handling form submit failure',
        level: 'info'
      })
      context.logger({
        message: event.type,
        level: 'error',
        context: {
          formId: event.payload.formId,
          errorDetail: event.payload.errorDetail
        }
      })
    }
  }
