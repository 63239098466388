import * as core from '@mt-webpages/core'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import { SentrySdkScopeConfig } from 'src/data/sentrySdkScopeConfig'
import { SentrySdkConfig } from 'src/data/sentrySdkConfig'

export const configureSdk = (config: SentrySdkConfig): void => {
  Sentry.init({
    dsn: config.dsn,
    environment: config.environment,
    release: config.release,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', /^\//]
      }),
      new Sentry.Integrations.GlobalHandlers({
        onerror: false,
        onunhandledrejection: false
      })
    ],
    tracesSampleRate: config.tracesSampleRate,
    allowUrls: config.allowUrls
  })
}

export const configureScope = (config: SentrySdkScopeConfig): void => {
  Sentry.configureScope(scope => {
    if (config.tags) {
      scope.setTags(config.tags)
    }
  })
}

export const createLogger = (
  config: SentrySdkConfig
): core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>> =>
  core.data.logger.filter(
    (message): message is typeof message =>
      !!Sentry.getCurrentHub().getClient() &&
      core.data.logLevel.LogLevelOrd[message.level] >= core.data.logLevel.LogLevelOrd[config.minimumLogLevel],
    core.data.logger.merge([
      core.data.logger.filter(
        (x): x is core.data.logEntry.LogEntry<string> => typeof x.message === 'string',
        log => {
          Sentry.captureMessage(log.message, {
            level: log.level,
            tags: log.tags,
            extra: log.context
          })
        }
      ),
      core.data.logger.filter(
        (x): x is core.data.logEntry.LogEntry<Error> => x.message instanceof Error,
        log => {
          Sentry.captureException(log.message, {
            level: log.level,
            tags: log.tags,
            extra: log.context
          })
        }
      )
    ])
  )
