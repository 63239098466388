import * as core from '@mt-webpages/core'

export const MtWebpagesHubspotFormApiSubmitErrorDetail =
  core.data.hubspotFormSubmitErrorDetail.HubspotFormSubmitErrorDetail

export type MtWebpagesHubspotFormApiSubmitErrorDetail =
  core.data.hubspotFormSubmitErrorDetail.HubspotFormSubmitErrorDetail

export const fromUnknown = (unknown: unknown): MtWebpagesHubspotFormApiSubmitErrorDetail => {
  const result = MtWebpagesHubspotFormApiSubmitErrorDetail.validate(unknown)
  return result.success ? result.value : { type: 'UnhandledError' }
}
