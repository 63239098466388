import * as core from '@mt-webpages/core'
import * as runtypes from 'runtypes'
import * as locale from './locale'

export const HubspotFormSubmitFieldErrorTranslation = runtypes.Dictionary(
  runtypes.Dictionary(runtypes.String, core.data.hubspotApiSubmitFormFieldErrorType.HubspotApiSubmitFormFieldErrorType),
  locale.Locale
)

export type HubspotFormSubmitFieldErrorTranslation = runtypes.Static<typeof HubspotFormSubmitFieldErrorTranslation>

// TODO: Setup Lokalise?
export const defaultTranslations: HubspotFormSubmitFieldErrorTranslation = {
  ja: {
    BLOCKED_EMAIL: 'このメールアドレスはご利用いただけません',
    REQUIRED_FIELD: '必須項目です',
    INPUT_TOO_LARGE: 'ご入力いただいた内容が長過ぎます'
  },
  en: {
    BLOCKED_EMAIL: 'Email is blocked',
    REQUIRED_FIELD: 'Field is required',
    INPUT_TOO_LARGE: 'Input is too large'
  }
}
