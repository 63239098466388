import * as rxjs from 'rxjs'

export type EventEmitter<A> = {
  emit: (event: A) => void
}

export const empty: EventEmitter<unknown> = {
  emit: () => {}
}

export const fromSubject = <A>(subject: rxjs.Subject<A>): EventEmitter<A> => ({
  emit: event => subject.next(event)
})
