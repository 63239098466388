import * as core from '@mt-webpages/core'

export const fromQuerySelector = (query: string, node: ParentNode): null | HTMLElement => {
  const element = node.querySelector(query)
  return element ? fromElement(element) : null
}

export const fromQuerySelectorAll = (query: string, node: ParentNode): HTMLElement[] => {
  return core.lib.array.filterMap(fromElement, Array.from(node.querySelectorAll(query)))
}

export const fromElement = (element: Element): null | HTMLElement => {
  return element instanceof HTMLElement ? element : null
}
