import * as runtypes from 'runtypes'

export const HubspotFormMetadata = runtypes.Record({
  formId: runtypes.String
})

export type HubspotFormMetadata = runtypes.Static<typeof HubspotFormMetadata>

export const fromFormElement = (element: HTMLElement): runtypes.Result<HubspotFormMetadata> => {
  return HubspotFormMetadata.validate({
    formId: element.dataset.hubspotFormId
  })
}
