import * as runtypes from 'runtypes'
import * as core from '@mt-webpages/core'

export const HubspotFormFieldMetadata = runtypes.Union(
  runtypes.Record({
    type: runtypes.Literal('checkbox'),
    name: runtypes.String,
    value: runtypes.String,
    checked: runtypes.Boolean,
    objectTypeId: runtypes.Optional(core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId),
    disabled: runtypes.Optional(runtypes.Boolean),
    required: runtypes.Optional(runtypes.Boolean),
    triggerFieldName: runtypes.Optional(runtypes.String),
    triggerFieldValue: runtypes.Optional(runtypes.String)
  }),
  runtypes.Record({
    type: runtypes.Literal('input'),
    name: runtypes.String,
    value: runtypes.String,
    objectTypeId: runtypes.Optional(core.data.hubspotFormFieldObjectTypeId.HubspotFormFieldObjectTypeId),
    disabled: runtypes.Optional(runtypes.Boolean),
    required: runtypes.Optional(runtypes.Boolean),
    triggerFieldName: runtypes.Optional(runtypes.String),
    triggerFieldValue: runtypes.Optional(runtypes.String)
  })
)

export type HubspotFormFieldMetadata = runtypes.Static<typeof HubspotFormFieldMetadata>

export const hasSubmittableValue = (metadata: HubspotFormFieldMetadata) => {
  return toSubmittableValue(metadata) !== null
}

// Inputs other than checkboxes are always considered submittable irrespective of value.
// A separate function must be used to check if they are active or not.
export const toSubmittableValue = HubspotFormFieldMetadata.match<string | null>(
  checkbox => (checkbox.checked ? checkbox.value : null),
  input => input.value
)
