import * as sessionStoreService from 'src/data/sessionStoreService'
import * as cookies from 'src/lib/cookies'

export type SessionCookieStoreContext = {
  window: Window
}

export const toSessionStoreService = (context: SessionCookieStoreContext): sessionStoreService.SessionStoreService => ({
  get: key => get(key)(context)
})

export const get = (key: string) => (context: SessionCookieStoreContext) => {
  return cookies.get(key)(context.window)
}
