import * as formInput from './formInput'
import * as hubspotSubmitFormErrorDetail from './hubspotSubmitFormErrorDetail'
import * as hubspotFormFieldMetadata from './hubspotFormFieldMetadata'

export type HubspotFormEvent =
  | HubspotFormSetFieldValueEvent
  | HubspotFormFieldDomChangeEvent
  | HubspotFormSubmitStartEvent
  | HubspotFormSubmitSuccessEvent
  | HubspotFormSubmitFailureEvent
  | HubspotFormSubmitCompleteEvent
  | Noop

export type HubspotFormSetFieldValueEvent = {
  type: 'setFieldValue'
  payload: {
    formId: string
    form: HTMLFormElement
    name: string
    value: string
  }
}

export type HubspotFormFieldDomChangeEvent = {
  type: 'fieldDomChange'
  payload: {
    formId: string
    form: HTMLFormElement
    metadata: hubspotFormFieldMetadata.HubspotFormFieldMetadata
  }
}

export type HubspotFormSubmitStartEvent = {
  type: 'formSubmitStart'
  payload: {
    formId: string
    form: HTMLFormElement
  }
}

export type HubspotFormSubmitFailureEvent = {
  type: 'formSubmitFailure'
  payload: {
    formId: string
    form: HTMLFormElement
    errorDetail: hubspotSubmitFormErrorDetail.HubspotSubmitFormErrorDetail
  }
}

export type HubspotFormSubmitSuccessEvent = {
  type: 'formSubmitSuccess'
  payload: {
    formId: string
    form: HTMLFormElement
    formInput: formInput.FormInput
  }
}

export type HubspotFormSubmitCompleteEvent = {
  type: 'formSubmitComplete'
  payload: {
    formId: string
    form: HTMLFormElement
  }
}

export type Noop = {
  type: 'noop'
}

export const setFieldValue = (payload: HubspotFormSetFieldValueEvent['payload']): HubspotFormSetFieldValueEvent => {
  return {
    type: 'setFieldValue',
    payload
  }
}

export const fieldDomChange = (payload: HubspotFormFieldDomChangeEvent['payload']): HubspotFormFieldDomChangeEvent => {
  return {
    type: 'fieldDomChange',
    payload
  }
}

export const formSubmitStart = (payload: HubspotFormSubmitStartEvent['payload']): HubspotFormSubmitStartEvent => {
  return {
    type: 'formSubmitStart',
    payload
  }
}

export const formSubmitFailure = (payload: HubspotFormSubmitFailureEvent['payload']): HubspotFormSubmitFailureEvent => {
  return {
    type: 'formSubmitFailure',
    payload
  }
}

export const formSubmitSuccess = (payload: HubspotFormSubmitSuccessEvent['payload']): HubspotFormSubmitSuccessEvent => {
  return {
    type: 'formSubmitSuccess',
    payload
  }
}

export const formSubmitComplete = (
  payload: HubspotFormSubmitCompleteEvent['payload']
): HubspotFormSubmitCompleteEvent => {
  return {
    type: 'formSubmitComplete',
    payload
  }
}

export const noop: Noop = {
  type: 'noop'
}
