import * as logger from '@mt-webpages/core/data/logger'
import * as logEntry from '@mt-webpages/core/data/logEntry'
import * as callback from 'src/data/callback'
import * as subscription from 'src/data/subscription'
import * as mtWebpagesWebEvent from 'src/data/mtWebpagesWebEvent'
import * as mtWebpagesWebContext from 'src/data/mtWebpagesWebContext'
import * as hubspotSubmitFormErrorDetail from 'src/data/hubspotSubmitFormErrorDetail'
import * as hubspotSubmitFormScriptConfig from 'src/data/hubspotSubmitFormScriptConfig'
import * as hubspotForm from 'src/lib/hubspotForm'

export const from =
  (config: hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig) =>
  (context: mtWebpagesWebContext.MtWebpagesWebContext): subscription.Subscription => {
    const { onFormEvent, ...rest } = config
    try {
      return hubspotForm.addFormEventListeners({
        window: context.window,
        logger: logger.pipe(logEntry.addContext(config))(context.logger),
        mtWebpagesWebSession: context.mtWebpagesWebSession,
        onFormEvent: callback.merge([onFormEvent || callback.empty, context.hubspotEventEmitter.emit]),
        ...context.hubspotFormConfig,
        ...hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig.check(rest)
      })
    } catch (error: unknown) {
      context.mtWebpagesWebEventEmitter.emit(
        mtWebpagesWebEvent.hubspotFormConfigurationFailure({
          formId: config.formId,
          errorDetail: hubspotSubmitFormErrorDetail.fromUnknownError(error)
        })
      )
      return subscription.empty
    }
  }
