import * as core from '@mt-webpages/core'
import * as runtypes from 'runtypes'
import * as htmlElement from 'src/lib/htmlElement'
import * as hubspotFormMetadata from './hubspotFormMetadata'
import * as hubspotFormConfig from './hubspotFormConfig'
import * as hubspotFormSubmitElement from './hubspotFormSubmitElement'
import * as hubspotFormSubmitFormValidationErrorDetail from './hubspotFormSubmitFormValidationErrorDetail'

export const HubspotFormElement = runtypes.InstanceOf(HTMLFormElement)

export type HubspotFormElement = runtypes.Static<typeof HubspotFormElement>

export const fromElement = (element: Element): HubspotFormElement => {
  return HubspotFormElement.check(element)
}

export const toHubstpotFormMetadata = (form: HubspotFormElement): hubspotFormMetadata.HubspotFormMetadata => {
  return hubspotFormMetadata.HubspotFormMetadata.check({
    formId: form.dataset.hubspotFormId
  })
}

export const toSubmitElement = (form: HubspotFormElement): hubspotFormSubmitElement.HubspotFormSubmitElement | null => {
  return core.lib.runtypes.result.toNullable(
    hubspotFormSubmitElement.HubspotFormSubmitElement.validate(
      htmlElement.fromQuerySelector('[type="submit"]', form) || htmlElement.fromQuerySelector('button', form)
    )
  )
}

export const setFormValidationErrorDetail =
  (
    errorDetail: hubspotFormSubmitFormValidationErrorDetail.HubspotFormSubmitFormValidationErrorDetail,
    form: HubspotFormElement
  ) =>
  (config: hubspotFormConfig.HubspotFormConfig) => {
    unsetFieldErrors(form)(config)
    core.lib.array
      .filterMap(([fieldName, errorTypes]) => {
        const fieldElement = htmlElement.fromQuerySelector(`[name=${fieldName}]`, form)
        return fieldElement ? ([fieldName, fieldElement, errorTypes] as const) : null
      }, Object.entries(errorDetail.fieldErrors))
      .forEach(([fieldName, fieldElement, errorTypes]) => {
        const errorListContainer = document.createElement('div')
        const errorList = document.createElement('ul')
        fieldElement.classList.add(config.formInvalidFieldCssClassname)
        errorListContainer.dataset[config.formFieldErrorDatasetIdentifier] = fieldName
        errorListContainer.classList.add(config.formfieldErrorListContainerCssClassname)
        errorListContainer.appendChild(errorList)

        errorTypes.forEach(errorType => {
          const errorListItem = document.createElement('li')
          errorListItem.classList.add(config.formfieldErrorListItemCssClassname)
          errorListItem.textContent = config.fieldErrorTranslation[config.locale][errorType]
          errorList.appendChild(errorListItem)
        })

        fieldElement.parentNode?.insertBefore(errorListContainer, fieldElement.nextSibling)
      })
  }

export const unsetFieldErrors = (form: HubspotFormElement) => (config: hubspotFormConfig.HubspotFormConfig) => {
  Array.from(form.querySelectorAll(`[data-${config.formFieldErrorDatasetIdentifier}]`)).forEach(error => {
    error.remove()
  })
  htmlElement.fromQuerySelectorAll(`[name]`, form).forEach(field => {
    field.classList.remove(config.formInvalidFieldCssClassname)
  })
}

export const setSubmitLoadingIndicator =
  (form: HubspotFormElement) => (config: hubspotFormConfig.HubspotFormConfig) => {
    const submitElement = toSubmitElement(form)
    if (submitElement) {
      submitElement.classList.add(config.formSubmitLoadingClassname)
      submitElement.disabled = true
    }
  }

export const unsetSubmitLoadingIndicator =
  (form: HubspotFormElement) => (config: hubspotFormConfig.HubspotFormConfig) => {
    const submitElement = toSubmitElement(form)
    if (submitElement) {
      submitElement.classList.remove(config.formSubmitLoadingClassname)
      submitElement.disabled = false
    }
  }
