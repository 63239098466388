import * as runtypes from 'runtypes'
import * as core from '@mt-webpages/core'

export const SentrySdkConfigSerialized = runtypes.Record({
  dsn: runtypes.String.withConstraint(x => x.length > 0),
  environment: runtypes.String.withConstraint(x => x.length > 0), // union?
  release: runtypes.Optional(runtypes.String.withConstraint(x => x.length > 0)),
  tracesSampleRate: runtypes.Optional(runtypes.Number),
  minimumLogLevel: core.data.logLevel.LogLevel
})

export type SentrySdkConfigSerialized = runtypes.Static<typeof SentrySdkConfigSerialized>

export type SentrySdkConfig = SentrySdkConfigSerialized & { allowUrls?: RegExp[] }
