import * as mtWebpagesHubspotFormApiSubmitErrorDetail from './mtWebpagesHubspotFormApiSubmitErrorDetail'

export class MtWebpagesHubspotFormApiSubmitError extends Error {
  detail: mtWebpagesHubspotFormApiSubmitErrorDetail.MtWebpagesHubspotFormApiSubmitErrorDetail

  constructor(detail: mtWebpagesHubspotFormApiSubmitErrorDetail.MtWebpagesHubspotFormApiSubmitErrorDetail) {
    super('MtWebpagesHubspotFormApiSubmitError')
    this.detail = detail
  }
}
