import * as htmlElement from 'src/lib/htmlElement'
import * as mtWebflowFormElementConfig from './mtWebflowFormElementConfig'

export type MtWebflowFormElement = HTMLFormElement

export const showResultElement = (query: string, form: MtWebflowFormElement) => {
  const component = form.parentNode && htmlElement.fromQuerySelector(query, form.parentNode)

  if (component) {
    form.style.display = 'none'
    component.style.display = 'block'
  }
}

export const showSuccessElement =
  (form: MtWebflowFormElement) => (config: mtWebflowFormElementConfig.MtWebflowFormElementConfig) => {
    showResultElement(config.formSuccessElementQuery, form)
  }

export const showFailureElement =
  (form: MtWebflowFormElement) => (config: mtWebflowFormElementConfig.MtWebflowFormElementConfig) => {
    showResultElement(config.formFailureElementQuery, form)
  }
