import * as runtypes from 'runtypes'

export const HubspotFormSubmitEvent = runtypes.Record({
  type: runtypes.Literal('hsFormCallback'),
  id: runtypes.String,
  eventName: runtypes.Union(runtypes.Literal('onFormSubmit')),
  data: runtypes.Optional(
    runtypes.Array(
      runtypes.Record({
        name: runtypes.String,
        value: runtypes.Unknown
      })
    )
  )
})

export type HubspotFormSubmitEvent = runtypes.Static<typeof HubspotFormSubmitEvent>

// https://community.hubspot.com/t5/APIs-Integrations/onFormSubmitted-does-not-return-form-data/m-p/282685
export const HubspotFormSubmittedEvent = runtypes.Record({
  type: runtypes.Literal('hsFormCallback'),
  id: runtypes.String,
  eventName: runtypes.Union(runtypes.Literal('onFormSubmitted'))
})

export type HubspotFormSubmittedEvent = runtypes.Static<typeof HubspotFormSubmittedEvent>

export const HubspotEvent = runtypes.Union(HubspotFormSubmitEvent, HubspotFormSubmittedEvent)

export type HubspotEvent = runtypes.Static<typeof HubspotEvent>

export const HubspotDomEvent = runtypes.InstanceOf(MessageEvent).And(
  runtypes.Record({
    data: HubspotEvent
  })
)

export type HubspotDomEvent = runtypes.Static<typeof HubspotDomEvent>
