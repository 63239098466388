import * as core from '@mt-webpages/core'
import * as httpClientService from 'src/data/httpClientService'

export type HttpClientContext = {
  client: (request: Request) => Promise<Response>
  logger?: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
}

export const toHttpClientService = (context: HttpClientContext): httpClientService.HttpClientService => {
  return {
    send: request => send(request)(context),
    json: request => json(request)(context)
  }
}

export const send =
  (request: Request) =>
  async (context: HttpClientContext): Promise<Response> => {
    let response: Response
    try {
      response = await context.client(request)
    } catch (error) {
      if (error instanceof Error) {
        throw new httpClientService.NetworkError(error)
      } else {
        throw new httpClientService.NetworkError(new Error('unknown error'))
      }
    }
    if (response.status < 200 || 400 <= response.status) {
      throw new httpClientService.HttpError(response, await decodeJsonBody(response))
    } else {
      return response
    }
  }

export const json =
  (request: Request) =>
  async (context: HttpClientContext): Promise<unknown> => {
    const response = await send(request)(context)
    return decodeJsonBody(response)
  }

export const decodeJsonBody = async (response: Response): Promise<unknown> => {
  try {
    return await response.json()
  } catch (e) {
    return null
  }
}
