import * as logger from '@mt-webpages/core/data/logger'
import * as logEntry from '@mt-webpages/core/data/logEntry'
import * as subscription from 'src/data/subscription'
import * as hubspotFormElement from 'src/data/hubspotFormElement'
import * as hubspotForm from 'src/lib/hubspotForm'

export type HubspotFormsContext = Omit<hubspotForm.HubspotFormContext, 'formId' | 'formElement'> & {
  formElementQuery: string
}

export const addFormEventListeners = (context: HubspotFormsContext): subscription.Subscription => {
  return subscription.merge(
    Array.from(context.window.document.querySelectorAll(context.formElementQuery))
      .map(element => hubspotFormElement.fromElement(element))
      .map(formElement =>
        hubspotForm.addFormEventListeners({
          formElement,
          ...context,
          ...hubspotFormElement.toHubstpotFormMetadata(formElement),
          logger: logger.pipe(logEntry.addContext(context))(context.logger)
        })
      )
  )
}
