import * as core from '@mt-webpages/core'
import * as dom from 'src/lib/dom'
import * as mtWebpagesWebEvent from 'src/data/mtWebpagesWebEvent'
import * as mtGtmEvent from 'src/data/mtGtmEvent'
import * as mtGtmService from 'src/data/mtGtmService'

export type MtWebpagesEventHandlerContext = {
  logger: core.data.logger.Logger<core.data.logEntry.LogEntry<string | Error>>
  mtGtm: mtGtmService.MtGtmService
  window: Window & globalThis.Window
}

export const handleEvent =
  (event: mtWebpagesWebEvent.MtWebpagesWebEvent) =>
  async (context: MtWebpagesEventHandlerContext): Promise<void> => {
    if (event.type === 'mtWebpagesWebLoad') {
      context.window.dispatchEvent(dom.createCustomEvent(event.type)(context.window))
    } else if (event.type === 'mtWebpagesWebHubspotFormSubmitSuccess') {
      context.mtGtm.sendEvent(
        mtGtmEvent.hubspotFormSubmissionEvent({
          formId: event.payload.formId
        })
      )
    } else if (event.type === 'mtWebpagesHubspotFormConfigurationFailure') {
      context.logger({
        message: event.type,
        level: 'error',
        context: {
          formId: event.payload.formId,
          errorDetail: event.payload.errorDetail
        }
      })
    } else if (event.type === 'mtWebpagesHubspotFormsConfigurationFailure') {
      context.logger({
        message: event.type,
        level: 'error',
        context: {
          errorDetail: event.payload.errorDetail
        }
      })
    } else {
      core.lib.function.absurd(event)
    }
  }
