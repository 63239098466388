export type HttpClientService = {
  send: (request: Request) => Promise<Response>
  json: (request: Request) => Promise<unknown>
}

export class RequestError extends Error {
  constructor(message: string, request: Request) {
    super(`Failed calling ${request.url}: ${message}`)
    this.name = 'RequestError'

    // necessary workaround for https://stackoverflow.com/questions/31089801/extending-error-in-javascript-with-es6-syntax-babel
    Object.setPrototypeOf(this, RequestError.prototype)
  }
}

export class NetworkError extends Error {
  constructor(error: Error) {
    super(`NetworkError: ${error.message}`)
  }
}

export class HttpError extends Error {
  response: Response
  body: unknown

  constructor(response: Response, body: unknown) {
    super('HttpError')
    this.response = response
    this.body = body
  }
}
