import * as runtypes from 'runtypes'
import * as hubspotSubmitFormScriptConfig from './hubspotSubmitFormScriptConfig'

export const HubspotSubmitFormsScriptConfig = hubspotSubmitFormScriptConfig.HubspotSubmitFormScriptConfig.omit(
  'formId',
  'formElement'
).And(
  runtypes.Record({
    formElementQuery: runtypes.Optional(runtypes.String)
  })
)

export type HubspotSubmitFormsScriptConfig = runtypes.Static<typeof HubspotSubmitFormsScriptConfig>
